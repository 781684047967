const dataFaqs = [
  {
    category: "Game",
    id: 1,
    title: "Where can I play Factions of Mora Ora?",
    text: "Link to game page.",
    show: "",
  },
  {
    category: "Game",
    id: 2,
    title: "Is the game available now?",
    text: "The game will become available at the same time as the NFT mint.",
    show: "",
  },
  {
    category: "Game",
    id: 3,
    title: "What do I need to play?",
    text: "You need to own a Factions of Mora Ora NFT.",
    show: "",
  },
  {
    category: "Game",
    id: 4,
    title: "What is $FOMO?",
    text: "$FOMO is Mora Ora’s native currency that is rewarded for successful in-game actions, and is the bedrock for in-game trading and commerce.",
    show: "",
  },
  {
    category: "General",
    id: 5,
    title: "What is Factions of Mora Ora?",
    text: "Factions of Mora Ora is a NFT project focused on narrative, quality art, and immersive game experiences. It is a collaborative tale of power, wealth, and magic, inspired by classic games such as Dungeons & Dragons and Settlers of Catan, and epics of the likes of Game of Thrones. 6 factions will fight for control of coveted resources on the newfound lands of Mora Ora. Each faction has unique characteristics and societies that users will take part in. Lead your faction to glory and reap the rewards!",
    show: "",
  },
  {
    category: "General",
    id: 6,
    title: "Where can I find the story?",
    text: "The narrative will be told through the Twitter page. Story installments will also be accessible on the game board.",
    show: "",
  },
  {
    category: "General",
    id: 7,
    title: "Where do I go to mint?",
    text: "Link to Mint page/ post mint and sell out ->“Mint has concluded, you can buy NFTs on [list of marketplaces]“",
    show: "",
  },
  {
    category: "General",
    id: 8,
    title: "What blockchain will you be minting on?",
    text: "Ethereum",
    show: "",
  },
  {
    category: "General",
    id: 9,
    title: "Where can I buy Factions of Mora Ora NFT’s",
    text: "You can buy Factions of Mora Ora NFT’s on [list of marketplaces]",
    show: "",
  },
  {
    category: "General",
    id: 10,
    title: "Where can I find out more about the project?",
    text: "You can follow us on Twitter (with a link to our twitter profile) where we will be posting the most up to date announcements about the project. ",
    show: "",
  },
  {
    category: "General",
    id: 11,
    title: "What are the NFTs?",
    text: "8,022 characters evenly split among 6 factions",
    show: "",
  },
];

export default dataFaqs;
