import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { OutsideClickHandler } from "../../hoc/outslideClickHandler";

export default function ModalWrapper({ open, onClose, title, children }) {
  return (
    <div className="pageDialog">
      <Dialog
        open={open}
        className="modalMian"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <OutsideClickHandler onOutsideClick={onClose}>
          <div className="modalWrapper">
            <div className="modalHeader">
              <h3>{title}</h3>
              <button onClick={onClose}>X</button>
            </div>
            <div className="modalBody">{children}</div>
          </div>
        </OutsideClickHandler>
      </Dialog>
    </div>
  );
}
