import { styled } from "@mui/material";
import { convertImageToViewportCoordinate } from "../../lib/ReactOpenSeadragon/Overlay";
import { useTiledImage } from "../../lib/ReactOpenSeadragon/TiledImage";
import useDeepZoomArea from "../../lib/ReactOpenSeadragon/lib/hooks/useDeepZoomArea";
import useOpenSeadragonHandler from "../../lib/ReactOpenSeadragon/lib/hooks/useOpenSeadragonHandler";
import OpenSeadragon from "openseadragon";
import React from "react";
import BoundaryHex from "./BoundaryHex";

const StyledBoundaryHexTheUrBaal = styled(BoundaryHex)`
  border-radius: 34% 37% 24% 31%;
  .hex-name-overlay-container {
    top: 52%;
    left: 47%;
  }
`;
const StyledBoundaryHexTheValerian = styled(BoundaryHex)`
  border-radius: 40% 41% 46% 30%;
  .hex-name-overlay-container {
    top: 50%;
    left: 50%;
  }
`;
const StyledBoundaryHexTheManrdakar = styled(BoundaryHex)`
  border-radius: 79% 22% 41% 30%;
  .hex-name-overlay-container {
    top: 38%;
    left: 47%;
  }
`;
const StyledBoundaryHexTheAethrin = styled(BoundaryHex)`
  border-radius: 24% 22% 40% 30%;
  .hex-name-overlay-container {
    top: 47%;
    left: 40%;
  }
`;
const StyledBoundaryHexTheHundra = styled(BoundaryHex)`
  border-radius: 85% 22% 40% 31%;
  .hex-name-overlay-container {
    top: 36%;
    left: 57%;
  }
`;

const StyledBoundaryHexTheCavalore = styled(BoundaryHex)`
  border-radius: 60% 37% 50% 31%;
  .hex-name-overlay-container {
    top: 50%;
    left: 56%;
  }
`;
const StyledBoundaryHexIllegardeSwamp = styled(BoundaryHex)`
  border-radius: 53% 35% 50% 52%;
  .hex-name-overlay-container {
    top: 42%;
    left: 52%;
  }
`;

const HexesCoordinates = [
  {
    name: "Illegarde Swamp",
    rectangle: {
      x: 4875.605379849619,
      y: 2196.7374965413546,
      width: 1800,
      height: 850,
    },
    zoomFitOffset: {
      width: 0.04,
    },
    component: StyledBoundaryHexIllegardeSwamp,
  },
  {
    name: "The Aethrin",
    component: StyledBoundaryHexTheAethrin,
    rectangle: {
      x: 5314.19387755102,
      y: 2903.012244897959,
      width: 1500,
      height: 1100,
    },
  },
  {
    name: "The Hundra",
    rectangle: {
      x: 3743.1527137041894,
      y: 2723.438705563909,
      width: 1600,
      height: 1250,
    },
    component: StyledBoundaryHexTheHundra,
    zoomFitOffset: {
      x: 0.02,
      y: -0.004,
    },
  },
  {
    name: "The Valerian",
    rectangle: {
      x: 6384.59659410761,
      y: 1386.50118494991,
      width: 1400,
      height: 1100,
    },
    component: StyledBoundaryHexTheValerian,
  },
  {
    name: "The Manrdakar",
    rectangle: {
      x: 6465.8020408163275,
      y: 2105.191836734694,
      width: 1700,
      height: 1300,
    },
    zoomFitOffset: {
      x: -0.02,
    },
    component: StyledBoundaryHexTheManrdakar,
  },
  {
    name: "The Cavalore",
    rectangle: {
      x: 3500,
      y: 1900,
      width: 1600,
      height: 1100,
    },
    component: StyledBoundaryHexTheCavalore,
    zoomFitOffset: {
      x: 0.02,
    },
  },
  {
    name: "The Ur'Baal",
    rectangle: {
      x: 4680.375510204081,
      y: 1200.5510204081631,
      width: 1750,
      height: 1100,
    },
    component: StyledBoundaryHexTheUrBaal,
  },
];

export default function Hexes() {
  const { image, inImage } = useTiledImage();
  const { viewer } = useDeepZoomArea();
  useOpenSeadragonHandler("update-viewport", () => {
    const zoom = viewer?.viewport?.getZoom();
    const locationNameContainer = document.getElementById("locationName");
    if (!locationNameContainer) return;
    locationNameContainer.innerHTML = "Location";
    if (zoom < 3.5) return;

    HexesCoordinates.some((hex) => {
      const viewPortBounds = viewer?.viewport?.getBounds?.();
      const { rectangle, name } = hex;
      const pointCoordinates =
        inImage && !hex?.viewportCoordinates
          ? convertImageToViewportCoordinate(rectangle, image)
          : new OpenSeadragon.Rect(
            rectangle.x,
            rectangle.y,
            rectangle.width,
            rectangle.height
          );
      const newX = viewPortBounds.x + viewPortBounds.width / 2;
      const newY = viewPortBounds.y + viewPortBounds.height / 2;
      const maxX = pointCoordinates.x + pointCoordinates.width;
      const maxY = pointCoordinates.y + pointCoordinates.height;
      if (
        newX < maxX &&
        newX > pointCoordinates?.x &&
        newY < maxY &&
        newY > pointCoordinates?.y
      ) {
        locationNameContainer.innerHTML = name;
        return true;
      }
      return false;
    });
  });

  return (
    <>
      {HexesCoordinates.map((hex) => {
        return (
          <hex.component
            zoomFitOffset={hex?.zoomFitOffset}
            key={hex.name}
            name={hex.name}
            rectangle={hex.rectangle}
          />
        );
      })}
    </>
  );
}
