import { ScaledOverlay } from "../../lib/ReactOpenSeadragon/Overlay";
import DiamondMarker from "./DiamondMarker";
import dataMarkers from "../../assets/fake-data/data-markers";
import { useCallback, useState } from "react";
import ModalWrapper from "../modals/ModalWrapper";
import { Box, Grid } from "@mui/material";
import useDeepZoomArea from "../../lib/ReactOpenSeadragon/lib/hooks/useDeepZoomArea";

export default function RoadMarkers() {
  const [activeIdx, setActiveIdx] = useState(null);
  const { viewer } = useDeepZoomArea();

  const handleActiveMarker = (idx) => {
    setActiveIdx(idx);
  };

  const handlePrevBtnClick = () => {
    const { coordinates } = dataMarkers[activeIdx - 1];
    viewer.viewport.panTo(coordinates, true);
    setActiveIdx((prev) => prev - 1);
  };

  const handleNextBtnClick = () => {
    const { coordinates } = dataMarkers[activeIdx + 1];
    viewer.viewport.panTo(coordinates, true);
    setActiveIdx((prev) => prev + 1);
  };

  const handleClose = useCallback((event, reason) => {
    setActiveIdx(null);
  }, []);

  const isModalOpen = !!(activeIdx || activeIdx === 0);

  return (
    <>
      <ScaledOverlay zIndex={2}>
        {dataMarkers.map((marker, idx) => (
          <DiamondMarker
            key={marker.id}
            style={marker.style}
            idx={idx}
            onActiveMarker={handleActiveMarker}
          />
        ))}
      </ScaledOverlay>
      {
        <ModalWrapper
          open={isModalOpen}
          onClose={handleClose}
          title={dataMarkers[activeIdx]?.title}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflow: "auto",
              maxHeight: "50vh",
              minHeight: "30vh",
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={dataMarkers[activeIdx]?.media ? 5 : 12} xs={12}>
                {dataMarkers[activeIdx]?.media && (
                  <div className="gamePic">
                    {dataMarkers[activeIdx]?.media?.type === "img" && (
                      <img
                        src={dataMarkers[activeIdx]?.media?.source}
                        alt="tweet-img"
                      />
                    )}
                    {dataMarkers[activeIdx]?.media?.type === "video" && (
                      <video
                        src={dataMarkers[activeIdx]?.media?.source}
                        alt="tweet-video"
                      />
                    )}
                  </div>
                )}
              </Grid>
              <Grid item md={dataMarkers[activeIdx]?.media ? 7 : 12} xs={12}>
                <div className="rightPan">
                  <p>{dataMarkers[activeIdx]?.text}</p>
                </div>
              </Grid>
            </Grid>
          </Box>
          <div className="actions">
            <button
              type="button"
              className="action-btn s1"
              onClick={handlePrevBtnClick}
              disabled={activeIdx === 0}
            >
              <span>Previous</span>
            </button>
            <button
              type="button"
              className="action-btn s1"
              onClick={handleNextBtnClick}
              disabled={activeIdx === dataMarkers.length - 1}
            >
              <span>Next</span>
            </button>
          </div>
        </ModalWrapper>
      }
    </>
  );
}
