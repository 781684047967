import React from 'react'
import sidekickImage from '../../assets/images/lore-banner.png';
import '../../scss/component/_section.scss'

const SideKick = () => {
    return (
        <div className='find-sidekick'>
            <div className='banner-inner'>
                <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                    <img src={sidekickImage} alt="sidekick logo" />
                </div>
                <div className='banner-inner__description col-sm-12 col-lg-8 col-xl-8'>
                    <h3>Find Your sidekick</h3>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus assumenda sint, in laudantium doloribus blanditiis officiis molestias nesciunt reiciendis facilis.</p>
                    <button className='banner-inner__btn action-btn'><span>Pair now</span></button>
                </div>
            </div>
        </div>
    )
}

export default SideKick