import React from "react";
import dataDocs from "../assets/fake-data/data-docs";
import DocsList from "../components/docs/DocsList";

const Docs = () => {
  return (
    <div className="wrapper docs">
      <DocsList data={dataDocs} />
    </div>
  );
};

export default Docs;
