import * as React from 'react';
import { Button, Grow } from '@mui/material';

export default function ModalWrapperThree({ open, onClose, title, children }) {
  const [changable, setchangable] = React.useState('Consumables');
  return (
    <div className="pageDialog">
      <Grow
        in={open}
        unmountOnExit
        mountOnEnter
        >
        <div className="modalWrapperThree">
          <div className="modalHeader">
            <Button
              className={`tab_btn ${
                changable === 'Consumables' && "active_tab"
              }`}
              onClick={() => setchangable('Consumables')}
            >
              Consumables
            </Button>
            <Button
              className={`tab_btn ${
                changable === 'Resources' && "active_tab"
              }`}
              onClick={() => setchangable('Resources')}
            >
              Resources
            </Button>

            {/* <button onClick={onClose} className={Styles.topmodalClose}>
              X
            </button> */}
          </div>
          <div className="modalBody">{children}</div>
        </div>
      </Grow>
    </div>
  );
}
