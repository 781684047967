import { createSlice } from "@reduxjs/toolkit";
import dataItem from '../../assets/fake-data/data-item';

const initialState = {
    items: dataItem,
    filters: [],
    search: ''
};

const { actions, reducer } = createSlice({
    name: 'nfts/slice',
    initialState,
    reducers: {
        addFilter: (state, { payload }) => {
            state.filters = [...state.filters, ...payload];
        },
        removeFilter: (state, { payload }) => { 
            state.filters = state.filters.filter((el) => el.value !== payload.value || el.type !== payload.type)
        },
        resetFilters: (state, action) => { 
            state = initialState;
        },
        search: (state, action) => { 
            state.search = action.payload;
        }
    }
})


export const {addFilter, removeFilter, search, resetFilters} = actions;

export default reducer;