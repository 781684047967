import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/landing-logo.svg";
import { ReactComponent as IconDiscord } from "../../assets/images/discordIcon.svg";
import { ReactComponent as IconTwitter } from "../../assets/images/twitter.svg";
import { ReactComponent as IconOpensea } from "../../assets/images/new-boat.svg";
import menus from "../../pages/menu";
import { ROUTE_PATH } from "../../constants/routes";

const otherPages = [
  {
    id: 1,
    name: "Terms and Conditions",
    links: "/",
  },
  {
    id: 2,
    name: "Privacy Policy",
    links: "/",
  },
];

const socialIcons = [
  {
    id: 1,
    item: (
      <IconDiscord
        style={{
          color: "white",
        }}
      />
    ),
    url: "/",
  },
  {
    id: 2,
    item: (
      <IconTwitter
        style={{
          color: "white",
        }}
      />
    ),
    url: "/",
  },
  {
    id: 3,
    item: (
      <IconOpensea
        style={{
          color: "white",
        }}
      />
    ),
    url: "/",
  },
];

const Footer = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="footer__bottom">
              <Link to={ROUTE_PATH.HOME} className="logo">
                <img src={logo} alt="Faction of mora ora" />
              </Link>

              <div className="center mb--30">
                <ul className="list">
                  {menus.map((data, idx) => (
                    <li key={idx} className="menu-item">
                      <Link to={data.links}>{data.name}</Link>
                    </li>
                  ))}
                </ul>
                <p className="copy">Copyright © 2023, Factions of mora ora.</p>
              </div>
              <div className="center mb--30">
                <ul className="list-social">
                  {socialIcons.map((icon) => (
                    <li key={icon.id}>
                      <Link to={icon.url}>{icon.item}</Link>
                    </li>
                  ))}
                </ul>
                <ul className="list list--other-pages">
                  {otherPages.map((data, idx) => (
                    <li key={idx} className="menu-item">
                      <Link className="menu-item-link" to={data.links}>
                        {data.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isVisible && (
        <Link to="#" onClick={scrollToTop} id="scroll-top">
          <span className="icon-arrow-top"></span>
        </Link>
      )}
    </footer>
  );
};

export default Footer;
