const dataRoadMap = [
    {
        id: 1,
        title: "The Cartographer's Odyssey",
        desc: 'Join the cartographer as he completes his perilous journey through Mora Ora, discovering the factions and wonders of the land.',
        class: ''
    },
    {
        id: 2,
        title: 'The Mint of Mora Ora.',
        desc: "It's time to stake your claim. Mint your character NFT and delve into the immersive world of Mora Ora. Strategize, fortify your faction, and lay the groundwork for the battles ahead",
        class: 'right'
    },
    {
        id: 3,
        title: 'The Rise of Fomorium',
        desc: 'Trade and commerce are expanded with the launch of $FOMO. In game actions have greater rewards… or consequences.',
        class: ''
    },
    {
        id: 4,
        title: 'The Clash of Factions',
        desc: 'Alliances crumble as war descends upon Mora Ora; factions fiercely vie for power, wealth, and the glory of the land.',
        class: 'right'
    },
    {
        id: 5,
        title: 'Undiscovered',
        desc: '???',
        class: ''
    },
    {
        id: 6,
        title: 'Undiscovered',
        desc: '???',
        class: 'right'
    },
]

export default dataRoadMap;