import secondDiamodImage from "../../assets/images/game01.png";

const dataMarkers = [
  {
    id: 1,
    media: null,
    title: "Title 1",
    text: "Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery.",
    style: {
      top: "229px",
      left: "354px",
    },
    coordinates: { x: 0.3480123536909384, y: 0.23133995026337262 },
    tweetLink: null,
  },

  {
    id: 2,
    media: {
      type: "img",
      source: secondDiamodImage,
    },
    title: "Title 2",
    text: "Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery.",
    style: {
      top: "211px",
      left: "390px",
    },
    tweetLink: null,
    coordinates: { x: 0.3881211232354662, y: 0.21899007489514255 },
  },
  {
    id: 3,
    media: null,
    title: "Title 3",
    text: "Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery.",
    style: {
      top: "200px",
      left: "419px",
    },
    tweetLink: null,
    coordinates: { x: 0.42369782842467985, y: 0.20573378665584974 },
  },
  {
    id: 4,
    media: {
      type: "img",
      source: secondDiamodImage,
    },
    title: "Title 4",
    text: "Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery.",
    style: {
      top: "180px",
      left: "468px",
    },
    tweetLink: null,
    coordinates: { x: 0.46256028027149737, y: 0.1869823703857392 },
  },
  {
    id: 5,
    media: null,
    title: "Title 5",
    text: "Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery.",
    style: {
      top: "154px",
      left: "510px",
    },
    tweetLink: null,
    coordinates: { x: 0.5244076062381441, y: 0.16125104874747687 },
  },
  {
    id: 6,
    media: {
      type: "img",
      source: secondDiamodImage,
    },
    title: "Title 6",
    text: "Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery. Paragraph of extra content may sit here if necessery.",
    style: {
      top: "165px",
      left: "580px",
    },
    tweetLink: null,
    coordinates: { x: 0.5748404769079328, y: 0.1649796651729145 },
  },
];

export default dataMarkers;
