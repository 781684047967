import OpenSeadragon from 'openseadragon';
import CloseIcon from '@mui/icons-material/Close';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import useOpenSeadragonHandler from './lib/hooks/useOpenSeadragonHandler';
import { debounce, styled } from '@mui/material';

const StyledCenterMarker = styled(CloseIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  color: yellow;
  pointer-events: none;
`;
export const DeepZoomAreaContext = createContext({});
export default function DeepZoomArea({
  children,
  className,
  style,
  options,
  onReady,
  enableDebugging
}) {
  const [viewer, setViewer] = useState(null);
  const [ready, setReady] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!ready) return;
    const viewer_ = OpenSeadragon({
      element: containerRef.current,
      ...options
    });
    window.viewer = viewer_;
    setViewer(viewer_);
    onReady?.(viewer_);
    return async () => {
      setReady(false);
      if (OpenSeadragon.isFullScreen()) {
        // need to avoid a crash by waiting for asynchronous leaving fullscreen
        // before making a new viewer attached to the same node
        await new Promise((resolve) => {
          viewer_.addOnceHandler('full-screen', resolve);
          viewer_.setFullScreen(false);
        });
      }
      viewer_.destroy();
      setReady(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, JSON.stringify(options), onReady]);

  const contextValue = useMemo(
    () => ({ viewer, containerRef, debugEnabled: Boolean(enableDebugging) }),
    [viewer, containerRef, enableDebugging]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const autoSetMinZoom = useCallback(
    debounce(() => {
      if (options?.homeFillsViewer) {
        viewer.viewport.minZoomLevel = viewer.viewport.getHomeZoom();
        viewer.viewport.applyConstraints();
      } else {
        viewer.viewport.minZoomLevel = options?.minZoomLevel;
      }
    }, 50),
    [options?.homeFillsViewer, options?.minZoomLevel, viewer]
  );

  useOpenSeadragonHandler(
    'update-viewport',
    autoSetMinZoom,
    { once: true },
    contextValue.viewer
  );

  //cursor grab and drag switch
  const restoreCursor = useCallback(() => {
    containerRef.current.style.cursor = 'grab';
    document.removeEventListener('click', restoreCursor);
  }, []);

  useOpenSeadragonHandler(
    'canvas-drag',
    () => {
      containerRef.current.style.cursor = 'grabbing';
      document.addEventListener('click', restoreCursor);
    },
    undefined,
    contextValue.viewer
  );

  return (
    <DeepZoomAreaContext.Provider value={contextValue}>
      <div
        ref={containerRef}
        style={{
          cursor: 'grab',
          ...style
        }}
        className={className}
      >
        {enableDebugging ? <StyledCenterMarker fontSize="medium" /> : null}
      </div>
      {viewer ? children : null}
    </DeepZoomAreaContext.Provider>
  );
}
