import React, { useEffect, useRef } from "react";


export const OutsideClickHandler = ({ children, onOutsideClick }) => {
    const wrapperRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          onOutsideClick();
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onOutsideClick]);
  
    return <div ref={wrapperRef}>{children}</div>;
  };