import React from "react";
import dataFaqs from "../assets/fake-data/data-faq";
import dataRoadMap from "../assets/fake-data/data-roadmap";
import dataTeam from "../assets/fake-data/dataTeam";
import About from "../components/about/About";
import FAQ from "../components/faqs/FAQ";
import Collection from "../components/collection/Collection";
import Roadmap from "../components/roadmap/Roadmap";
import Banner from "../components/banner/Banner";
import Team from "../components/team/Team";
import dataItem from "../assets/fake-data/data-item";

const Home = () => {
  return (
    <div className="home-1 wrapper">
      <Banner />
      <About />
      <Collection data={dataItem} />
      <Roadmap data={dataRoadMap} />
      <Team data={dataTeam} />
      <FAQ data={dataFaqs} />
    </div>
  );
};

export default Home;
