import WhitepaperBg from "../images/background/tiles/Whitepaper.jpg";
import GameRules from "../images/background/tiles/Game_Rules.jpg";

const dataDocs = [
  {
    id: 1,
    title: "Whitepaper",
    img: WhitepaperBg,
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, incidunt eveniet veritatis assumenda, nesciunt autem quisquam, earum reprehenderit debitis nisi dignissimos modi nam blanditiis consequatur. Repellat consequuntur consectetur dicta mollitia delectus, quae error corporis at perspiciatis quam ut id laudantium expedita harum numquam. Animi est laborum dolore accusamus laboriosam repellat libero atque, hic culpa autem minima, facere, voluptas possimus repudiandae dicta recusandae magnam neque aperiam maxime accusantium! Unde, dolorum consectetur vitae eos rem ipsum, iste et illo maiores officiis eaque dolorem rerum veniam obcaecati sint aliquam vero. Unde reprehenderit et consequuntur explicabo velit. Dignissimos maxime ex corrupti commodi perferendis aperiam voluptatem cum tempora. Quis illum laborum officia possimus rerum recusandae quasi vitae ex nulla asperiores aliquid provident ducimus minus harum facere, veritatis adipisci reiciendis debitis. Alias eveniet repellat ut, adipisci deleniti laudantium, vero perspiciatis molestiae sed dolorum totam excepturi ratione blanditiis eum quia, sunt ipsum amet velit non? Porro, rem unde et nostrum voluptatibus, nisi ducimus ipsam dolor consequatur dolores, suscipit eligendi? Sed accusamus, quisquam accusantium voluptatibus velit quae temporibus ad asperiores rerum magnam in quam repudiandae est a. Reiciendis sit qui fugiat beatae dolorem incidunt hic, porro quae eius. Possimus dolorum quidem ipsam vitae saepe at sed, nam ab.",
  },
  {
    id: 2,
    title: "Game Rules",
    img: GameRules,
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis, incidunt eveniet veritatis assumenda, nesciunt autem quisquam, earum reprehenderit debitis nisi dignissimos modi nam blanditiis consequatur. Repellat consequuntur consectetur dicta mollitia delectus, quae error corporis at perspiciatis quam ut id laudantium expedita harum numquam. Animi est laborum dolore accusamus laboriosam repellat libero atque, hic culpa autem minima, facere, voluptas possimus repudiandae dicta recusandae magnam neque aperiam maxime accusantium! Unde, dolorum consectetur vitae eos rem ipsum, iste et illo maiores officiis eaque dolorem rerum veniam obcaecati sint aliquam vero. Unde reprehenderit et consequuntur explicabo velit. Dignissimos maxime ex corrupti commodi perferendis aperiam voluptatem cum tempora. Quis illum laborum officia possimus rerum recusandae quasi vitae ex nulla asperiores aliquid provident ducimus minus harum facere, veritatis adipisci reiciendis debitis. Alias eveniet repellat ut, adipisci deleniti laudantium, vero perspiciatis molestiae sed dolorum totam excepturi ratione blanditiis eum quia, sunt ipsum amet velit non? Porro, rem unde et nostrum voluptatibus, nisi ducimus ipsam dolor consequatur dolores, suscipit eligendi? Sed accusamus, quisquam accusantium voluptatibus velit quae temporibus ad asperiores rerum magnam in quam repudiandae est a. Reiciendis sit qui fugiat beatae dolorem incidunt hic, porro quae eius. Possimus dolorum quidem ipsam vitae saepe at sed, nam ab.",
  },
];

export default dataDocs;
