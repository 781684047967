import OnStrangeShoresBg from "../images/background/tiles/On_Strange_Shores.jpg";
import FoundingOfTheFactionsBg from "../images/background/tiles/Founding_of_the_Factions.jpg";
import ANewResourceBg from "../images/background/tiles/A_New_Resource.jpg";
import TheSixSidedWarBg from "../images/background/tiles/The_Six_Sided_War.jpg";
import AethrinBg from "../images/background/tiles/Aethrin.jpg";
import ValerianBg from "../images/background/tiles/Valerian.jpg";
import UrBaalBg from "../images/background/tiles/Ur_Baal.jpg";
import UndiscoveredBg from "../images/background/tiles/Undiscovered.jpg";
import MandrakarBg from "../images/background/tiles/Mandrakar.jpg";
import IllegardeSwampBg from "../images/background/tiles/Illegarde_Swamp.jpg";
import HundraBg from "../images/background/tiles/Hundra.jpg";
import CavaloreBg from "../images/background/tiles/Cavalore.jpg";

const dataLors = [
  {
    id: 1,
    category: "Mora Ora",
    quote: `<p><i><span>We came by the boatloads, in galleons, longboats, and barges, our ships heavy in the water as we made for the shore. We were summoned to the continent by the same siren song&mdash;the promise of untouched land, ripe for anyone with the steel and the spirit to take. And the chance to begin again.&rdquo;</span></i></p>
                </br>
                <p><i><span>&mdash; Journals of Korinn Cesarus, Valerian Historian</span></i></p>`,
    docs: [
      {
        id: 1,
        title: "On Strange Shores",
        img: OnStrangeShoresBg,
        text: `</br>
                        <p><span>The Age of Discovery was born from necessity: Aurelium, the Old Continent, was nearly torn apart by the Corpolius Plague, numerous wars for power, and the corrupt practices of nobles and merchants alike. The Old World needed a fresh source of lifeblood to survive.&nbsp;</span></p>
                        </br>
                        <p><span>Soon, news of a continent across the Spiraling Sea swept through the kingdoms&mdash;tales of sprawling forests, verdant fields, and snow-capped mountains field the desire to cross the perilous ocean and set eyes on a new world. The kingdoms built great ships and sailed east, further than any voyager dared. And they found it.</span></p>
                        </br>
                        <p><span>They called the new land Mora Ora, the Blessed Continent.&nbsp;</span></p>`,
      },
      {
        id: 2,
        title: "Founding of the Factions",
        img: FoundingOfTheFactionsBg,
        text: `</br>
                        <p><span>Among the first to land was the Valerian Empire. Composed of holy orders of knights, nobles, and clerics dedicated to honor, valor, and the service of their religion, the Valerians came to view Mora Ora as their promised land, a place where they could draw resources to help their failing Empire back on its feet.</span></p>
                        </br>
                        <p><span>With the Valerians came the elves of the Aethrin Kingdoms. While they long been allies in various wars, the elves planned to make a new start in Mora Ora&rsquo;s forests, far from human despoilers of nature. By becoming the dominant race on the continent, they could create the natural paradise that they have always dreamed of as their birthright.</span></p>
                        </br>
                        <p><span>The Hundar, fierce Viking warriors who hailed from the Frozen South, quickly followed. They have sought the lands to conquer and project their influence throughout the world. They quickly set up settlements along the southern coastlines of Mora Ora.</span></p>
                        </br>
                        <p><span>Months later, a new colony materialized seemingly overnight in the mountains. The Cavalore Sect of mages have come seeking new knowledge in this seemingly magical land, and would stop at nothing to obtain it.</span></p>
                        </br>
                        <p><span>It was not only the living who migrated to Mora Ora; the dead came as well. Many who died from the Corpolius Plague returned as undead: the Ur&rsquo;Baal. Fleeing persecution from Aurelium, the Ur'Baal crossed the Spiraling Sea on ghost ships and founded a city in the deserts of the New World.&nbsp;</span></p>
                        </br>
                        <p><span>Finally, the Mandrakar were beastfolk who were discovered living in the eastern waters of the continent. Initially believed to be natives to Mora Ora, the mages soon deduced that these creatures were actually descended from the first expedition that came from Aurelium, given up for lost decades ago. The settlers had attempted to build their settlement on top of some strange ruins, and the ancient magic there altered their forms to resemble beasts.</span></p>`,
      },
      {
        id: 3,
        title: "A New Resource",
        img: ANewResourceBg,
        text: `</br>
                    <p><span>Rumors of an ancient magic that could change physical forms piqued the curiosity of many new settlers. An expedition into the jungles soon uncovered the ruins; research showed they had been left behind by a legendary race known as Fomorians. Having mysteriously vanished, they have left behind artifacts made from an arcane metal, which the mages have named Fomorium. This metal has the ability to absorb and store magic, which gives its wielders amazing power.</span></p>
                    </br>
                    <p><span>When it became clear that this mineral could be found in various locations throughout the continent, the six factions feverishly rushed to mine it all.&nbsp;</span></p>
                    </br>
                    <p><span>Each faction sought their own goals. The Valerians believed this metal was a gift from the gods, the divine cure they could use to revive their Empire. The Elves wanted to enforce the rule of Nature throughout the New World and wanted to use Fomorium to protect and expand their forest borders. The Hundra sought Fomorium to create magical weapons to pillage and conquer. The Netherfolk wanted to use it to create more of their kind and take control of the sunlit lands. The Cavalore Mages were hungry for new arcane knowledge, but also believed Fomorium was dangerous and should be kept away from other factions. Finally, the Mandrakar wished to preserve their way of life in Mora Ora and needed Fomorium to keep the other factions at bay.</span></p>`,
      },
      {
        id: 4,
        title: "The Six-Sided War",
        img: TheSixSidedWarBg,
        text: `<p><span>The Great Push, as it was called, was an effort made by all factions to find as much Fomorium as they could within their territories and beyond.</span></p>
                        </br>
                        <p><span>At the frontlines of the Push were the Valerians and the Elves. Hungry for the riches and power that the resource promised, they searched high and low for veins they could mine. They invaded the jungles where the Mandrakar made their home and secured them as porters, miners, and guides&mdash;at the cost of their freedom.&nbsp;</span></p>
                        </br>
                        <p><span>While searching the jungles, however, they ran into their ancient enemies, the Hundar. A battle was quickly joined as the competing sides rushed to remove a competitor. However, the Valerians maneuvered to leave the elves fighting the Hundar while they continued the hunt for Fomorium.&nbsp;</span></p>
                        </br>
                        <p><span>While searching the mountains, the Valerians found an Ur&rsquo;Baal mining camp that was extracting from an enormous Fomorium vein. Realizing they could not let an unholy race gain any of this divine resource, they attacked the Ur&rsquo;Baal and destroyed the camp. However, the Empire eventually discovered the extracted minerals missing from the depot. Magical residue indicated that the Cavalore mages had taken the Fomorium while the two sides fought.</span></p>
                        </br>
                        <p><span>The Mandrakar, meanwhile, took advantage of the fighting between the Hundra and elves to break free of their chains and drive all the factions out of their territory. Due to the loss of elven lives, the Aethrin leaders dissolved their alliance with the Valerian Empire and retreated to their forest enclaves to nurse their wounds.&nbsp;</span></p>
                        </br>
                        <p><span>To date, violence continues to break out among six factions as each compete for territory and resources. Each side knows that whoever wields the most of this magical resource will control the destiny of the Blessed Continent.&nbsp;</span></p>`,
      },
      {
        id: 5,
        title: "Summary",
        text: `</br>
                        <p><span>Over time, 6 powerful factions emerged as the powerful leaders of the new land. But, the new land had more to it than originally thought. Discoveries of powerful items and resources led to quick development, but the scarcity of these items took its toll. Fueled by the desire for power, and a rich life for their faction, these previously allied factions went to war over territory and resources.</span></p>`,
      },
    ],
  },
  {
    id: 2,
    category: "The 6 Factions",
    docs: [
      {
        id: 1,
        title: "Aethrin",
        img: AethrinBg,
        text: `<p><i><span>&ldquo;Long as the forest roots run deep, we elves shall never perish.&rdquo;</span></i></p>
                        </br>
                        <p><i><span>&mdash; from the collected writings of Camleth Artoroa, Elvish poet-warrior</span></i></p>
                        </br>
                        <p><span>The Aethrin, which in elvish means &ldquo;The Fairest Folk,&rdquo; are the elven race of Mora Ora. After defeating their mutual enemies, the ghuls, the elves maintained a long-standing alliance with the human Valerian empire. However, the discovery of Fomorium has caused a deep rift in elven-human relations. Now the elves seek Fomorium to turn Mora Ora into a natural paradise where they may live in peace and cultivate their ichors.</span></p>
                        </br>
                        <p><span>A graceful, venerable race known for their longevity, their magic, and their love for Nature, the elves of the Aethrin have grown weary of the avarice of the other races and seek a land they can truly call their own.</span></p>
                        </br>
                        <h4><span>History&nbsp;</span></h4>
                        </br>
                        <p><span>The Aethrin were among the first to settle the continent of Aurelia. Over the centuries, various elvish kingdoms rose across the Aurelian forests, and while they had disagreements, the elves lived in peace.&nbsp;</span></p>
                        </br>
                        <p><span>This peace was broken when the ghul tribes came to enslave the elves and rob their forests of timber. It took centuries of grueling battles for the elves to halt the ghuls&rsquo;s advance. What tipped the balance was meeting another race&mdash;the humans. Sharing a mutual hatred for the ghuls, the humans and the Aethrin forged a pact that finally turned the war in their favor. The two partners hunted the ghuls out of existence, forging a powerful alliance.</span></p>
                        </br>
                        <h5><span>The Fall of the Elves</span></h5>
                        </br>
                        <p><span>The Aethrin took great pride in sharing their knowledge with humans, educating them on statecraft, trade, and architecture. With their help, the humans created the Valerian Empire, their largest and most powerful nation.</span></p>
                        </br>
                        <p><span>And yet, even this long standing alliance began to fracture. The humans&rsquo; short lives drove them to greed and destructive ambition. Before long, human communities started encroaching on elvish forests. Soon the elves found themselves in a similar situation with the ghuls, only they were losing their lands to a beloved ally.</span></p>
                        </br>
                        <h5><span>Ancestral Lands</span></h5>
                        </br>
                        </br>
                        <p><span>When word came of a new continent across the sea, the elves saw a chance to start anew. They sent immigrants to live in Mora Ora in hopes of founding a new colony, aiming to lay claim on favored lands before others. The Valerian Empire&rsquo;s plans for expansion in the new continent, however, quickly dashed these hopes&mdash;until the discovery of Fomorium.</span></p>
                        </br>
                        <p><span>The Fomorian ruins changed everything for the Aethrin. Given the similarity in architecture and language, the elves began to believe that they were descended from Fomorians. The most hardline of elves began to advocate Mora Ora as the ancestral nation of elves and pushed to claim the entire continent.&nbsp;</span></p>
                        </br>
                        <p><span>The recent debacle where the Valerians left the Aethrin to face the Hundra alone drove the final nail in the alliance&rsquo;s coffin. The elves abandoned their treaty with the humans, returned to their forest sanctuary, and declared Mora Ora as their land by right.</span></p>
                        </br>
                        <h4><span>Culture and Society</span></h4>
                        </br>
                        <p><span>The Aethrin are tall, slender, and are best known for their musical voices and sharp-tipped ears. Their skin tones vary from fair (in Aurelia) to light brown (southern parts of Mora Ora). They weave their clothes from silk made from leaves, spiderwebs, and silkworms.</span></p>
                        </br>
                        <p><span>Being fervent nature worshippers, the elves are known for creating the finest druids, rangers, and herbalists. Their love of the woodlands is best seen in their architecture, which melds harmoniously with nature.</span></p>
                        </br>
                        <p><span>The Aethrin on Mora Ora are far more militant than their counterparts in the Old Continent. Now that they have found what they believe is their ancestral home, they will do anything to keep it. For them, the New World should be a verdant paradise filled with the forests and meadows elves love. No more wanton destruction of trees to fuel human ambitions, no more dangerous pursuit of magic in pursuit of insatiable curiosity. </span></p>`,
      },
      {
        id: 2,
        title: "Cavalore",
        img: CavaloreBg,
        text: `<p><i><span >&ldquo;If a little knowledge poses a great danger, then a wealth of it is a prelude to the apocalypse. For the good of the world, all magic must reside in our hands.&rdquo;</span></i></p>
                    </br>
                    <p><i><span >&mdash; Speaker Wodrom, Archmage of the Red Council&nbsp;</span></i></p>
                    </br>
                    <p><span >Secretive and isolated, the Cavalore Mages Sect jealously guard their magical knowledge the way a dragon hoards gold. They move in the shadows, preventing all kinds of magical threats and safeguarding the world.&nbsp;</span></p>
                    </br>
                    <p><span >The mages have stayed secret for ages, but the promise of knowledge held by Fomorium&mdash;and the danger the world faces if it fell into the wrong hands&mdash;has driven them to act in the open. Now they challenge all other factions for control of the new continent.</span></p>
                    </br>
                    <h4><span >History</span></h4>
                    </br>
                    <p><span >In the beginning, when mortals first discovered magic, there were no organized groups of wizards. Individuals learned magic and took apprentices in their own fashion without unity or fealty. Then the Orlox appeared. Interdimensional demons that consumed all things magical, they poured through a tear in reality and began attacking wizards and magical creatures alike.&nbsp;</span></p>
                    </br>
                    <p><span >The mages had no choice but to band together for survival. By combining their powers, they succeeded in stopping a full-scale invasion of the Orlox, sealing the rift for all of time. They then decided to band together to protect the world from further magical threats. This agreement gave rise to the Mages Sects.&nbsp;</span></p>
                    </br>
                    <p><span >With the bonds formed through defeat of a common enemy</span></p>
                    </br>
                    <p><span >The Cavalore Mages became the most influential of these Sects, thanks to their discovery of Soma Roots. These magical root crops grow well in places inhabited by magic creatures. To maintain their potency, the Cavalore collect them using special spades made from orichalcum, a metal impervious to magic.</span></p>
                    </br>
                    <p><span >The Cavalore believed that magic must be held in the hand of mages alone, as they are the only ones with the knowledge and wisdom to use them. Thus they instituted laws to protect the magical world, demanding that wizards keep their existence secret.&nbsp;</span></p>
                    </br>
                    <p><span >However, their growth meant it became more difficult to stay hidden. The Valerian Empire became aware of mages and, seeing their witchcraft a threat to their religion, initiated a crusade to hunt them down.&nbsp;</span></p>
                    </br>
                    <p><span >The Cavalore did not want to be pulled into a war with the Empire and its allies. They needed a way to end this inquisition quickly and quietly. One enterprising mage, Venin, came up with an idea&mdash;create a controlled epidemic that would last for several years, siphoning away the Empire&rsquo;s resources and attention. He secreted himself away in a graveyard at the heart of the Empire and performed a ritual to create such a plague&mdash;but something went wrong. The sickness took hold and spun out of control, spreading like wildfire throughout the land. Thus the Corpolius Plague came to be.</span></p>
                    </br>
                    <p><span >The mages could not understand what exactly went wrong with the ritual&mdash;it seemed some higher power interfered to make the plague more potent than necessary. Yet despite this tragedy, the mages benefited from the Empire&rsquo;s weakness and were able to turn their attention back to their studies.&nbsp;</span></p>
                    </br>
                    <h5><span >Across the Sea</span></h5>
                    </br>
                    <p><span >But the plague had an unintended effect&mdash;it spurred the Age of Discovery, which led to the settlement of Mora Ora and the finding of a new resource: Fomorium. The mages discovered this fact long after the other factions did and scrambled to follow.&nbsp;</span></p>
                    </br>
                    <p><span >The mages see Fomorium as a destabilizing factor in the balance of the world, as its power could provoke greed and havoc on an unprecedented scale. Thus they have shed their secrecy for a more aggressive tactic: establishing a stronghold on Mora Ora and directly challenging the other factions for control of the resource.</span></p>
                    </br>
                    <h4><span >Society and Culture</span></h4>
                    </br>
                    <p><span >The Cavalore devote themselves to a lifetime of magical study and meditation. Ranks began at the apprentice level, followed by journeyman, adept, mage, master, and finally, archmage. The most experienced archmages become part of the Red Council, a governing body that decides all crucial matters in Cavalore society.</span></p>
                    </br>
                    <h5><span >Mana Thistle</span></h5>
                    </br>
                    <p><span >Mana Thistle is a rare and extraordinary flowering plant native to the hilly grasslands of the Cavalore Mages' territory. Its vibrant blue petals and twisted, spiraling stalks make it easily distinguishable from other plants in the region. This magical plant is highly attuned to the magical energies that flow throughout the land, allowing it to absorb and store these energies within its fibers.</span></p>
                    </br>
                    <h5><span >Growth and Harvesting</span></h5>
                    </br>
                    <p><span >Mana Thistle has a unique life cycle, with its growth and potency being closely tied to the fluctuations of magical energy in the environment. The plant thrives in areas with a high concentration of magical energies, and it is most commonly found in close proximity to ley lines, ancient ruins, or other sources of magical power. Harvesting Mana Thistle requires great care and expertise, as improper handling can cause the stored magical energy within the plant to be lost or released in an uncontrolled manner. Only skilled and knowledgeable Cavalore Mages are entrusted with the task of collecting these precious plants.</span></p>
                    </br>
                    <h5><span >Uses and Applications</span></h5>
                    </br>
                    <p><span >When properly harvested and processed, Mana Thistle has a wide range of applications in the magical arts. Its most common use is in the creation of potent elixirs and potions that can temporarily boost a mage's magical prowess, enabling them to cast more powerful and complex spells. These elixirs are highly sought after by the Cavalore Mages for their ability to give them an edge in magical duels and battles.</span></p>
                    <p><span >Mana Thistle can also be used as a crucial ingredient in the crafting of magical items and artifacts, imbuing them with the raw power and connection to the natural world that the plant possesses. Wands, staves, and other magical implements crafted with Mana Thistle are known for their exceptional ability to channel and focus magical energy, making them prized possessions among the mages.</span></p>
                    </br>
                    <p><span >In addition to its use in potion-making and item crafting, Mana Thistle is also an essential reagent in many powerful spells and enchantments that require a strong connection to the land and its magical energies. The plant's unique properties allow it to serve as a conduit between the mage and the natural world, enabling them to harness the full potential of the magical energies that flow through the environment.</span></p>
                    </br>
                    <p><span >Cultural Significance</span></p>
                    <p><span >The Mana Thistle has become a symbol of the Cavalore Mages' deep connection to the magical energies of the world and their mastery over the arcane arts. The vibrant blue petals of the plant are often depicted in the art and heraldry of the faction, representing the power and wisdom of the mages. The plant is also revered for its role in helping the mages maintain their status as the guardians of magic, providing them with the tools and resources they need to protect the world from magical threats and maintain the balance of power.</span></p>
                    </br>
                    <h4><span >Arsenal</span></h4>
                    </br>
                    <p><span >The Cavalore eschew weaponry and armor for magical tools and spells. Wand, scepters, staves, crystals, and spellbooks are all part of a typical wizard&rsquo;s gear. They have mastered many disciplines of magic and often summon creatures to fight for them rather than risk their own lives in direct combat.</span></p>`,
      },
      {
        id: 3,
        title: "Hundra",
        img: HundraBg,
        text: `<p><i><span>&ldquo;They come with their longships and their crystal-rimmed axes</span></i></p>
                        <p><i><span>Their hearts as cold as the steel they bear.&rdquo;</span></i></p>
                        </br>
                        <p><i><span>&mdash; from the collected writings of Camleth Artoroa, Elvish poet-warrior&nbsp;</span></i></p>
                        </br>
                        <p><span>Coastal towns and villages have long learned to fear the Hundra. A lingering scourge of the Valerian Empire, the sight of the dragon-headed longships and the blare of the first warhorns can drive grown men mad with fear.&nbsp;&nbsp;&nbsp;</span></p>
                        </br>
                        <p><span>With their Grimfrost-empowered berserkers and spearmen, the Hundra have come to conquer Mora Ora and build a mountain of bones from the corpses of the Empire.</span></p>
                        </br>
                        <h4><span>History</span></h4>
                        </br>
                        <p><span>Two hundred years before the Age of Discovery, the Hundra were a collection of tribes inhabiting the Old Continent&rsquo;s Frozen South. Despite their strength in arms, their fractured population made it easy for the Valerian Empire to subjugate them, forcing them to pay tribute to the Emperor.&nbsp;</span></p>
                        </br>
                        <p><span>To ensure their obedience, chieftains were often forced to surrender their young children as hostages to a nearby Valerian kingdom. Two such children from the Volgen tribe, Alrik and Hyldir, were held captive, raised, and educated in the Imperial way. They never forgot their Hundrarian roots, and when they came of age, they attempted to escape back to their tribe.</span></p>
                        </br>
                        <p><span>The Empire&rsquo;s soldiers hunted the two children. During the pursuit, Alrik was shot and killed by a crossbow bolt. Alone in the freezing cold, Hyldir prayed to her old gods for aid, and Orlon, god of winter, answered her cry. She discovered an axe-shaped crystal in the snow&mdash;Grimfrost. With this improvised weapon, she killed the pursuing soldiers and managed to escape back to her tribe, swearing a mighty oath to avenge her brother.</span></p>
                        </br>
                        <h5><span>A New Horde</span></h5>
                        </br>
                        <p><span>Hyldir eventually became the chief of her tribe. She brokered alliances with other Hundrarian clans, going as far as killing leaders who would not gather under her banner. Eventually, she assembled a massive force of clansmen that would soon be the terror of the Empire.</span></p>
                        </br>
                        <p><span>Hyldir began raiding the outskirts of the Empire, meeting tremendous success. No coastal village or town was safe from her longships and pillaging raiders. The Hundra looted and burned settlements along the Empire coastlines, taking gold and slaves alike. With the loot, the Hundrarian army swelled. Filled with confidence, Hyldir pushed deeper into Valerian territory, dreaming of cutting off the snake&rsquo;s head by reaching the capital.&nbsp;</span></p>
                        </br>
                        <p><span>Hyldir met her end in Camleth, a Valerian citadel she had taken. Her forward position eventually led to her death after her army was trapped and besieged. With Hyldir gone, the mighty Hundra were forced to stop their advance and give ground. While still powerful, Hyldir&rsquo;s successors were not as driven or as competent, relegating the Hundra to pillaging coastlines once more.</span></p>
                        </br>
                        <h5><span>A Chance at Renewal&nbsp;</span></h5>
                        </br>
                        <p><span>The discovery of Mora Ora reinvigorated the Hundra. They sought to gain control of this new continent and its rich resources so they could renew the offensive against the Empire and finally rid the world of its yoke. They staked their claim on the New World by creating a settlement along the western coast.</span></p>
                        </br>
                        <h4><span>Culture and Society</span></h4>
                        </br>
                        <p><span>Contrary to the Empire&rsquo;s stories of brutes and barbarians, the Hundra have an ordered, stratified society. Hundrarian culture is centered on tribes led by chieftains. Next in line were the jarls (nobles), then the karls (workers), and finally, the thralls (slaves). Fishing, trade, and raiding formed the basis of their economy. Women were educated and held in high regard, often fighting alongside the men as spear-wives and shieldmaidens.</span></p>
                        </br>
                        <h4><span >Military Tactics</span></h4>
                        </br>
                        <p><span >The Hundra are known for their use of battleaxes and claymores. The strongest berserkers wielding Grimfrost axes can split open a knight in plate armor outright. For heavy warfare they favor lamellar armor, but prefer the mobility of cloth and hide for raids.</span></p>
                        </br>
                        <p><span >Being great sailors, they use their familiarity with the tides to quickly take them to coastal towns and villages they can raid. When battling at sea, they prefer to board, rather than sink, and use their cannons to cover their retreat.</span></p>
                        `,
      },
      {
        id: 4,
        title: "Mandrakar",
        img: MandrakarBg,
        text: `<p><i><span>&ldquo;Not just her seas, her rivers and lakes, but the continent entire&mdash;Mora Ora belongs to us as we belong to her.&rdquo; 
        </span></p></i>
        </br>
        <p><i><span>&mdash; Lord Adaro of the Mandrakar</span></i></p>
                        </br>
                        <p><span>They attack without warning&mdash;a forest of spears rising from the waves, poisoned darts that slay the watchmen, webbed hands that reach over the deck to drag the unwary into the depths. All who enter Mandrakar territory have reason to fear&mdash;these amphibious folk will stop at nothing to defend their homes.&nbsp;</span></p>
                        </br>
                        <p><span>At first, the Mandrakar lived in peace, fishing and cultivating their Ember Pearls in the shallows of the New World&rsquo;s shores. But this peaceful life was shattered with the arrival of more colonists from the Old Continent. Now, the Folk of the Waves have no choice but to fight for their freedom and their right as a sovereign nation.&nbsp;</span></p>
                        </br>
                        <h4><span>History</span></h4>
                        </br>
                        <p><span>The first settlers that came to Mora Ora were all castaways. The poor, the sick, the meager, the criminals&mdash;everyone deemed unfit for Aurelian society were sent to settle a rumored new continent. No one expected them to survive the Spiraling Sea, but the settlers defeated the odds.</span></p>
                        </br>
                        <p><span>They landed on Mora Ora, an untamed wilderness where they could build a new life. The settlers hastily established a camp at the eastern bay, which eventually evolved into a town they named Mandrakar. While life was never easy, the settlers found they could prosper away from the uncaring Empire.</span></p>
                        </br>
                        <h5><span>The Changed Ones</span></h5>
                        </br>
                        <p><span>One day, the settlers made a startling discovery; a ruined temple at the bottom of the bay. Within, explorers found an altar embedded with an odd mineral&mdash;Fomorium. Touching the altar caused light to flash from the temple. When the light subsided, the explorers and the people in Mandrakar discovered their bodies had changed. They now possessed gills that allowed them to breathe below the water as well as above, and fins that enabled them to swim like a fish. Their skin was covered with protective scales and their eyes could see even in the darkest depths.&nbsp;</span></p>
                        </br>
                        <p><span>The Mandrakar, as they began calling themselves, took to living below the water as well as above, spreading throughout the rivers and shorelines of Mora Ora.</span></p>
                        </br>
                        <h5><span>Emancipation</span></h5>
                        </br>
                        <p><span>Their prosperous life didn&rsquo;t last. Drawn by fantastic tales from explorers, other colonists arrived from the Old Continent 78 years after the first expedition. Not recognizing the Mandrakar as former humans, the colonists enslaved them, hoping to find the source of their treasures. They took what Ember Pearls and Fomorium they could find and demanded more.&nbsp;</span></p>
                        </br>
                        <p><span>At first, the Mandrakar had no choice but to comply. But as the factions began fighting amongst themselves, they found their chance for an insurrection. The Manrakar threw off the yoke of the Empire and retreated to the waters. After that, no ship on Mora Ora was safe from the Folk of the Waves.</span></p>
                        </br>
                        <h4><span>Culture and Society&nbsp;</span></h4>
                        </br>
                        <p><span>The Mandrakar are an amphibious race that can survive equally well on land or in water. Their special gills let them breathe even in the depths of the sea or in lakes. This makes them highly adaptive to the coastlines of Mora Ora.&nbsp;</span></p>
                        </br>
                        <p><span>In the heart of Mandrakar territory, the extraordinary underwater plant known as Tidebloom flourishes within the vibrant coral reefs and dense kelp forests. With long, slender stalks swaying gracefully alongside the ocean currents, the Tidebloom seamlessly blends into its aquatic paradise. Its broad, translucent leaves, adorned with delicate veins of iridescent blue and green, exude an ethereal, otherworldly appearance.</span></p>
                        </br>
                        <p><span>As night falls and the moon casts its silver light upon the waters, the Tidebloom's true beauty emerges. The mesmerizing, bioluminescent flowers emit a soft, soothing glow in shades of turquoise, cerulean, and azure. These luminous petals dance and flicker like underwater flames, pulsating with the heartbeat of the ocean itself. The enchanting display bathes the underwater world in a symphony of blues and greens, casting kaleidoscopic reflections upon the surrounding corals and rocks. Schools of fish dart in and out of the light, their scales reflecting the glow like tiny, living mirrors.</span></p>
                        </br>
                        <p><span>This breathtaking sight instills a sense of awe and reverence in the hearts of the Mandrakar people, who understand the power and wonder of the Tidebloom as a gift to be cherished and protected. They are forever grateful for the beauty and unique properties it provides, a true testament to the magic that dwells within their world.</span></p>
                        </br>
                        <p><span>The plant produces bioluminescent flowers during certain lunar cycles, and these flowers can be harvested and refined to create a powerful adhesive substance. This adhesive, known as Tideglue, can be used to augment the Mandrakar's weapons and armor, making them significantly lighter and more flexible while still providing ample protection. Additionally, the bioluminescent properties of the Tidebloom can be utilized to create a potent elixir that enhances the Mandrakar's natural senses, granting them heightened perception and agility underwater, enabling them to navigate and outmaneuver their enemies with ease in the dark depths of the ocean.</span></p>
                        </br>
                        <p><span>Moreover, the Tideglue has proven crucial in repairing equipment on the battlefield, allowing the Mandrakar warriors to maintain the integrity of their gear even in the midst of combat. In times of peace, Tideglue is also used in the construction of their underwater homes, ensuring they remain watertight and robust against the ocean's relentless pressure.</span></p>
                        </br>
                        <h4><span>Military Tactics</span></h4>
                        </br>
                        <p><span>The Mandrakar are experts in the use of spears and nets&mdash;their usual gear when fishing. They have since developed poison-coated blowdarts that work exceptionally well underwater. They have also befriended creatures of the deep, calling on hippocampi to serve as mounts during their raids.</span></p>
                        </br>
                        `,
      },
      {
        id: 5,
        title: `Ur'Baal`,
        img: UrBaalBg,
        text: `<p><i><span>&ldquo;Our Lady welcomes you to her shelter and her service. Eternal life shall she grant to her faithful, and death to the unbeliever.&rdquo;</span></i></p>
                        </br>
                        <p><i><span>&mdash; Myrkal, High Priestess of Baali</span></i></p>
                        </br>
                        <p><span>The Empire hates them and attacks them on sight. The mages declared them traitors to their kind. The common folk fear them as harbingers of the Plague. They are the Ur&rsquo;Baal&mdash;the necromancers.&nbsp;</span></p>
                        </br>
                        <p><span>The Grand Cult of the Ur&rsquo;Baal originated from the Aurelia Mages Sects. Their leader, Argento Lysander, was cast out for his arcane research, which delved into the mystery of death and unlife. Shunned by the rest of the mages, these necromancers, as they came to be called, allied with Baali, Goddess of the Dead, who gave them her blessing and the promise of power.&nbsp;</span></p>
                        </br>
                        <p><span>Now, wielding necromantic magic and the power of Pyroclastic Essence, the Ur&rsquo;Baal seek control over Mora Ora to gather Fomorium to achieve Lysander&rsquo;s ultimate goal: eternal life.</span></p>
                        </br>
                        <h4><span>History</span></h4>
                        </br>
                        <p><span>Argento Lysander was an archmage who lived in Aurelia at the time when the Cavalore Mages Sect was gaining influence. While a proponent of the Cavalore&rsquo;s tenets, his outlook on magic changed when he lost his only child to a laboratory fire. His personal tragedy pushed him to find a way of preserving life beyond one&rsquo;s mortal body. This inevitably led him to research necromancy.</span></p>
                        </br>
                        <p><span>Lysander&rsquo;s initial successes attracted followers, yet despite his efforts to conceal his studies, he caught the Cavalore&rsquo;s attention. The mages attempted to arrest him for researching &ldquo;forbidden magic,&rdquo; but Lysander and his apprentices escaped and went into exile. They continued their research while hiding like rats in graveyards and catacombs.&nbsp;</span></p>
                        </br>
                        <p><span>Through it all, Lysander&rsquo;s goal never changed: he believed life was too precious to end so briefly. If other beings such as elves, dragons, and even gods could live for so long, why not humans?</span></p>
                        </br>
                        <p><span>It was during one of their experiments to create new types of undead that Lysander made contact with the Goddess of Death, Baali.&nbsp;</span></p>
                        </br>
                        <h5><span>Call of Baali</span></h5>
                        </br>
                        <p><span>The deity invited Lysander and his followers to join her cult. In return, she promised them a chance to attain greater power and advance their knowledge of necromancy. Seeing their opportunity, Lysander agreed. He renamed his faction the Ur&rsquo;Baal and carried the goddess&rsquo;s blessing, allowing him an unnaturally long lifespan.&nbsp;&nbsp;</span></p>
                        </br>
                        <p><span>Baali told her new followers of the Cavalore mages&rsquo; plan to create a plague in the heart of the Empire. She commanded her Ur&rsquo;Baal to secretly replace the ingredients of the ritual with items infused with the Death Goddess&rsquo; power. The result was the Corpolius Plague, a sickness that spread across the continent, killing hundreds of thousands and all but bringing the Empire to its knees. This tremendous loss of life empowered Baali while providing the Ur&rsquo;Baal fresh subjects for experimentation. The cult served the dark goddess and recruited more mages to their order.&nbsp;</span></p>
                        </br>
                        <p><span>Baali gave the Ur&rsquo;Baal knowledge of Pyroclastic Essence, a volatile substance that forms in the heart of magma chambers. It radiates corrupted magic that allows the necromancers to cast powerful spells or raise more undead.&nbsp;</span></p>
                        </br>
                        <p><span>Despite their newfound power, the Ur&rsquo;Baal found themselves no closer to their goal&mdash;all the undead they had created were mindless and far from a true continuation of a person&rsquo;s life. Discontented murmurs spread among their ranks, and even Lysander could not deny that Baali had not fulfilled her promise.&nbsp;&nbsp;</span></p>
                        </br>
                        <h5><span>Ghost of a Chance</span></h5>
                        </br>
                        <p><span>With the discovery of Mora Ora, the Ur&rsquo;Baal saw an opportunity to gain a new form of power. Through Fomorium, they hope to gain a magical resource that would finally help them create intelligent undead. They hope to finally master death and throw off the yoke of Baali.</span></p>
                        </br>
                        <h4><span>Culture and Society&nbsp;</span></h4>
                        </br>
                        <p><span>In time, Ur&rsquo;Baal members retained the ranks of the Mages Sect, namely apprentice, journeyman, adept, mage, master, and finally, archmage.&nbsp;</span></p>
                        </br>
                        <p><span>The primary focus of necromancy is the creation of undead. These creations retain some memories and basic functions, but their body continues to rot away until they are no longer functional. The necromancers hope to one day create truly intelligent undead who can survive indefinitely, meaning that the Ur&rsquo;Baal would have truly conquered death.</span></p>
                        </br>
                        <h4><span>Pyroclastic Essence</span></h4>
                        </br>
                        <p><span>In the heart of the volcanic region, the ground trembles with the fury of the earth, and the air is thick with the acrid scent of sulfur. Amidst the searing heat and rivers of molten rock, a dark substance unique to this area forms, hidden within the depths of the magma chambers. It is known as the Pyroclastic Essence&mdash;a deadly mixture of molten rock, dark magic, and the souls of the damned. The Ur'Baal have an innate affinity for harvesting and wielding its power, thanks to their connection with the Goddess of Death and their mastery of necromancy.</span></p>
                        </br>
                        <p><span>The Pyroclastic Essence is a viscous, tar-like substance, as black as the hearts of those who dare to harness it. Its creation is a slow, arduous process, resulting from the mingling of dark energies, volcanic fury, and the anguished cries of souls lost to the fiery depths. The essence is highly volatile and dangerous to handle, even for the most skilled of practitioners. It pulses with an ominous energy, as if alive, eager to unleash its destructive potential upon the world.</span></p>
                        </br>
                        <p><span>To extract the Pyroclastic Essence, one must undertake a perilous journey to the heart of the volcanic region, braving treacherous terrain, lethal temperatures, and the constant threat of eruption. Those who venture into the magma chambers must protect themselves from the searing heat and toxic fumes, while simultaneously seeking out the telltale signs of the essence's presence.</span></p>
                        </br>
                        <p><span>Once a vein of Pyroclastic Essence is discovered, a complex ritual is performed to safely extract it. The ritual involves a mixture of incantations, the use of enchanted tools, and the offering of a living sacrifice to appease the spirits dwelling within the volcanic depths. Upon completion of the ritual, the essence is drawn forth from the magma chamber, solidifying into dark, glassy globules that can be safely transported.</span></p>
                        </br>
                        <p><span>The Ur'Baal harness the Pyroclastic Essence for various purposes, the most prominent of which is the creation of powerful undead minions known as Infernal Revenants. These fiery undead creatures are both fearsome and relentless, their bodies cloaked in the molten essence that grants them immunity to fire and imbues them with explosive abilities. When unleashed upon the battlefield, Infernal Revenants leave nothing but scorched earth and the screams of their enemies in their wake.</span></p>
                        </br>
                        <p><span>In addition to the creation of Infernal Revenants, the Pyroclastic Essence is also used to enhance the Ur'Baal's necromantic spells. When combined with their dark magic, the essence grants their spells a fiery intensity that is difficult to counter. The power of the Pyroclastic Essence is as volatile as the earth from which it was born, and in the hands of those who dare to risk the fury of the volcanic depths, it becomes a deadly weapon capable of reshaping the world to their will.</span></p>`,
      },
      {
        id: 6,
        title: `Valerian`,
        img: ValerianBg,
        text: `<p><i><span>&ldquo;Strength and faith, blood and honor</span></i></p>
                        <p><i><span>Let all gather beneath the light we bear!&rdquo;</span></i></p>
                        </br>
                        <p><i><span>&mdash;Valerian Knights motto</span></i></p>
                        </br>
                        <p><span>Unbending as their traditions, the Empire of Valeria stands as the oldest and most powerful human empire. Legends abound of their knights, whose strength and holy weapons have conquered dragons, ghuls, and evil sorcerers alike. They will stop at nothing to impose the will of their god and their emperor.</span></p>
                        </br>
                        <p><span>Yet for all its power, the Empire is on the wane, eaten from within by the Corpolius Plague and without by the Hundra barbarians. New lands were needed, prompting them to set sail east across the Spiraling Sea. They dream of renewing their Empire&rsquo;s glory and providing a new sanctuary for future generations.</span></p>
                        </br>
                        <h4><span>History</span></h4>
                        </br>
                        <p><span>Before the Valerian Empire, humans were scattered across petty kingdoms inhabiting the Old Continent. Their lack of unity allowed them to fall victim to a vicious and barbaric horde: the ghuls. For a time, it seemed every province would fall to this malevolent army, until a young blacksmith named Antoine was granted a vision by the god Lorac the Redeemer. Under his guidance, Antoine became a cleric and forged the very first weapon crafted from Hallowed Ore.</span></p>
                        </br>
                        <p><span>Assuming the title Saint Valerius, he created a holy order of clerics devoted to forging with Hallowed Ore. The armor and weapons made by the Order of the Forge gave humanity the strength to unite and drive back the ghuls. A later alliance with the elves drove the final nail in the ghuls&rsquo; coffin.&nbsp;</span></p>
                        </br>
                        <p><span>Under the tutelage of the elves, the humans forged a great empire under Auric the First, who named his rule after their beloved saint. With sword and banner, he marched his army into a war of conquest. When Emperor Auric ended his reign, the Empire stretched all across Aurelia.</span></p>
                        </br>
                        <h5><span>The Empire in Retreat</span></h5>
                        </br>
                        <p><span>Over the centuries, the Valerian Empire became a victim of its own success.</span></p>
                        </br>
                        <p><span>With fewer enemies to fight, the knights grew soft and the clerics more interested in indulgences than saving souls. With fewer defenders on its borders, another threat came: the Hundra, raiders from the Frozen North, started encroaching on Valerian lands.&nbsp;</span></p>
                        </br>
                        <p><span>Yet that was not the worst of their troubles. Overcrowding and poor living conditions for the working class sprung a vicious plague, striking deep within the heart of the Empire. The Corpolius Plague killed off hundreds of thousands, turning many of them into undead.&nbsp;</span></p>
                        </br>
                        <p><span>The knights and clerics held the tide of threats at bay, but the damage was done. The Empire&rsquo;s wealth and power were on the wane and provinces were on the brink of secession. Humanity needed a new start or it would be the Empire&rsquo;s end.</span></p>
                        </br>
                        </br>
                        <p><span>That new start came in the form of a newly discovered continent across the sea: Mora Ora.</span></p>
                        </br>
                        <h5><span>New Land, New Hopes</span></h5>
                        </br>
                        <p><span>Together with the elves, the Valerian Empire sent an expedition to the new continent. Mora Ora meant new resources and territories for the Empire, possibly extending its life a thousand more years. The discovery of Fomorium made their objective even more important. The Empire must capture the new continent before any other faction.</span></p>
                        </br>
                        <p><span>Few historians remember that a First Expedition was sent to Mora Ora, but wisely avoid discussing it.</span></p>
                        </br>
                        <h4><span>Military Tactics</span></h4>
                        </br>
                        <p><span>The knights are best known for their heavy armor and weapons, making their cavalry charges near invincible. In battle, the Valerians field their knights, pikemen, swordsmen, crossbowmen, and heavy cavalry. Under good leadership and in an open field, theirs is a fighting force without comparison.</span></p>`,
      },
    ],
  },
  {
    id: 3,
    category: "Other",
    docs: [
      {
        id: 1,
        title: "Illegarde Swamp (Dead Zone)",
        img: IllegardeSwampBg,
        text: `<p><span>Nestled in the very center of Mora Ora&rsquo;s continent is a fetid land of death and illusion. A morass composed of black willows, hummocks, and brackish water, Illegarde Swamp is fed by rivers from the nearby snow-covered mountains, thereby maintaining its lush ecosystem.&nbsp;</span></p>
                        </br>
                        <p><span>Nothing about Illegarde Swamp is normal. Its creatures, from its spiders to its crocodiles, grow unusually large, posing a hazard for all who cross. The roots of its trees rise out of the water like enormous snakes, enough for travelers to walk on. There are very few reliable landmarks, and it is easy to get lost in the swamp.</span></p>
                        </br>
                        <p><span>There are rumors of ruins in Illegarde, places that may hold power and answers to the mysteries of the continent. But not even the most intrepid explorers wish to stay long in the swamp.</span></p>
                        </br>
                        <p><span>The greatest hazard of Illegarde is the mist that rises from the ground. No one, not even the mages, knows where this mist comes from. But those enveloped by this unnatural fog begin to experience hallucinations so life-like that they lose their grip on reality. What may seem like solid ground might give way to slime-covered water, and stepping onto a log bridge might lead you into the waiting jaws of a giant crocodile.&nbsp;</span></p>
                        </br>
                        <p><span>This is why travelers tend to avoid the swamp. Those who must cross it keep a wary eye out for this maddening fog, for a single shift in the wind may mean the loss of an entire unwary caravan.&nbsp;</span></p>
                        </br>
                        <p><span>Travelers who survive the mists have shared stories of distant figures walking through the fog. Some speculate these are victims, trapped in an eternal dream within the mists. Others believe that something far more sinister walks through Illegarde Swamp.</span></p>`,
      },
      {
        id: 2,
        title: "Undiscovered",
        text: `<h4>???</h4>`,
        img: UndiscoveredBg,
      },
      {
        id: 3,
        title: "Undiscovered",
        text: `<h4>???</h4>`,
        img: UndiscoveredBg,
      },
    ],
  },
];

export default dataLors;
