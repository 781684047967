import React, { useState } from "react";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { useDispatch } from "react-redux";
import { addFilter } from "../../redux/nfts/nftsSlice";
import { Link } from "react-router-dom";

const Collection = ({ data }) => {
  const dispatch = useDispatch();

  const [dataBlock] = useState({
    subheading: "Our Speciality",
    heading: "OUR COLLECTION",
  });
  const onClick = (attrs) => {
    dispatch(addFilter(attrs));
  };
  return (
    <section className="project" id="collection">
      <div className="shape right"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h3 className="heading">{dataBlock.heading}</h3>
            </div>
            <Swiper
              className="project-swiper"
              spaceBetween={30}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
              }}
              loop={true}
              modules={[Navigation, Pagination]}
              navigation
              pagination={{
                clickable: true,
              }}
            >
              {data.map((idx) => (
                <SwiperSlide key={idx.id}>
                  <div className="swiper-slide">
                    <div className="project-box">
                      <div className="image">
                        <Link
                          to="/gallery"
                          onClick={() => onClick(idx.attributes)}
                        >
                          <img src={idx.img} alt="Cyfonii" />
                        </Link>
                      </div>
                      <div className="content">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => onClick(idx.attributes)}
                          className="h5 title"
                        >
                          {idx.title}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Collection;
