import Box from '@mui/material/Box';
import { debounce, styled } from '@mui/material';
import assest from '../../assets/map';
import { useState, useRef } from 'react';
import useOpenSeadragonHandler from '../../lib/ReactOpenSeadragon/lib/hooks/useOpenSeadragonHandler';

const StyledHexes = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'scale'
})`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
  transform: translate(-50%, -50%);
  opacity: 1;
  .name-container {
    transform: scale(1.2);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    img {
      max-width: none !important;
    }import { assest } from '@/json/assest';

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 0.8vw;
      font-family: 'Rajdhani', sans-serif;
      text-transform: uppercase;
      color: #d3cfc9;
      line-height: 1;
      font-weight: 700;
      position: absolute;
      margin-bottom: 0px;
    }
  }
`;
export default function HexPositionOverlay({ name, className, ...rest }) {
  const textRef = useRef(null);
  const thisHexNameRef = useRef(null);

  const [size, setSize] = useState({
    height: 33,
    width: 125
  });

  const debouncedSetSize = useRef(
    debounce(() => {
      const extendWidth = window.innerWidth * 1/100;
      const extendHeight = window.innerHeight * 0.5/100;
      setSize({
        width: textRef?.current?.offsetWidth
          ? textRef.current.offsetWidth + extendWidth
          : 125,
        height: textRef?.current?.offsetHeight
          ? textRef.current.offsetHeight + extendHeight
          : 33
      });
    }, 0)
  ).current;

  const zoomHandlerRef = useRef(({ zoom }) => {
    debouncedSetSize();
    if (zoom) {
      if (thisHexNameRef.current) {
        thisHexNameRef.current.style.opacity = zoom > 3.5 ? 0 : 1;
      }
    }
  }).current;

  useOpenSeadragonHandler('zoom', zoomHandlerRef);
  useOpenSeadragonHandler('update-viewport', zoomHandlerRef, { once: true });

  return (
    <StyledHexes
      {...rest}
      ref={thisHexNameRef}
      className={`hex-name-overlay-container ${className || ""}`}
    >
      <div className="name-container">
        <img
          // loading="lazy"
          src={assest.borderFrame}
          alt={name}
          height={size.height}
          width={size.width}
        />
        <p ref={textRef}>{name}</p>
      </div>
    </StyledHexes>
  );
}
