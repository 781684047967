import { ReactComponent as FactionIcon } from '../assets/images/i-type.svg';
import { ReactComponent as BackgroundIcon } from '../assets/images/i-background.svg';
import { ReactComponent as ClothingIcon } from '../assets/images/i-clothing.svg';
import { ReactComponent as HeadIcon } from '../assets/images/i-headgear.svg';
import { ReactComponent as Weapon } from '../assets/images/i-weapon.svg';
import { ReactComponent as Hair } from '../assets/images/i-hair.svg';
import { ReactComponent as Eyes } from '../assets/images/i-eyes.svg';


export const filtersIcons = [
  {
    type: "Faction",
    icon: <FactionIcon style={{ fontSize: 22 }} />,
  },
  {
    type: "Background",
    icon: <BackgroundIcon style={{ fontSize: 22 }} />,
  },
  {
    type: "Clothing",
    icon: <ClothingIcon style={{ fontSize: 22 }} />,
  },
  {
    type: "Head Item",
    icon: <HeadIcon style={{ fontSize: 22 }} />,
  },
  {
    type: "Weapon",
    icon: <Weapon style={{ fontSize: 22 }} />,
  },
  {
    type: "Hair",
    icon: <Hair style={{ fontSize: 22 }} />,
  },
  {
    type: "Eyes",
    icon: <Eyes style={{ fontSize: 22 }} />,
  },
]