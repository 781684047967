import React from "react";
import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap-accordion";

// const FIRST_DOC_INDEX = 0;
const Doc = ({ title, text, img, idx }) => {
  return (
    <Accordion
      title={
        <h3
          className="d-block my-0"
          style={{
            background: `url(${img}) no-repeat center`,
            backgroundSize: "cover",
            width: "100%",
            padding: "1rem 1.25rem",
          }}
        >
          {title}
        </h3>
      }
    >
      <div className="bloglist__main">
        <div>
          <div className="line"></div>
          <div className="content-d">
            <div className="main">
              <p className="doc-content">{text}</p>
            </div>
          </div>
        </div>
      </div>
    </Accordion>
  );
};

Doc.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  idx: PropTypes.number,
  img: PropTypes.string,
};

export default Doc;
