import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import LoreList from "../components/lore/LoreList";
import dataLors from "../assets/fake-data/data-lore";

const Lore = () => {
  return (
    <div className="wrapper lore">
      <section className="banner banner--lore" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="faq__main flat-tabs">
              <Tabs className="lore-tabs">
                <TabList className="menu-tab">
                  {dataLors.map((idx) => (
                    <Tab className="fs-14 h6" key={idx.id}>
                      {idx.category}
                    </Tab>
                  ))}
                </TabList>

                {dataLors.map((tab) => (
                  <TabPanel key={tab.id} className="content-tab">
                    <div className="content-inner">
                      <div className="flat-accordion">
                        <LoreList
                          docs={tab.docs}
                          key={tab.id}
                          quote={tab.quote}
                        />
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lore;
