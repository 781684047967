import { useCallback, useRef } from 'react';
import useOpenSeadragonHandler from './useOpenSeadragonHandler';

export default function usePreventClickRef() {
  const preventClickRef = useRef(false);
  const stopPreventClick = useCallback(() => {
    preventClickRef.current = false;
    document.removeEventListener('click', stopPreventClick);
  }, []);

  useOpenSeadragonHandler('canvas-drag', () => {
    if (!preventClickRef.current) {
      preventClickRef.current = true;
      document.addEventListener('click', stopPreventClick);
    }
  });

  return { preventClickRef };
}
