import React, { useEffect, useRef } from "react";
import { Accordion } from "react-bootstrap-accordion";
import PropTypes from "prop-types";

const LoreItem = ({ title, text, img, quote, isOpen }) => {
  const contentRef = useRef(null);
  const quoteRef = useRef(null);

  useEffect(() => {
    if (contentRef && text) {
      contentRef.current.innerHTML = text;
    }
  }, [contentRef, text]);

  useEffect(() => {
    if (quoteRef && quote) {
      quoteRef.current.innerHTML = quote;
    }
  }, [quote]);

  return (
    <>
      <Accordion
        title={
          <h3
            style={{
              background: `url(${img}) no-repeat center`,
              backgroundSize: "cover",
              width: "100%",
              padding: "1rem 1.25rem",
            }}
            className="d-block my-0"
          >
            {title}
          </h3>
        }
        className="flat-toggle h6"
        show={isOpen}
      >
        {quote && <div ref={quoteRef} className="mb-5"></div>}
        <div className="line" />
        <div
          ref={contentRef}
          className="toggle-content pb-3 lore-content"
        ></div>
      </Accordion>
    </>
  );
};

LoreItem.prototype = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  quote: PropTypes.string,
  isOpen: PropTypes.bool,
  img: PropTypes.string,
};
export default LoreItem;
