
import img1 from '../images/team01.png'
import img2 from '../images/team02.png'
import img3 from '../images/team03.png'
import img4 from '../images/team04.png'
import img5 from '../images/team05.png'
import img6 from '../images/team06.png'
import img7 from '../images/team07.png'
import img8 from '../images/team08.png'

const dataItem = [
    {
        id: 1,
        img: img1,
        title: '3D Digital Artwork',
        attributes: [
            {
                type: "Faction",
                value: "The Hundra",
            },
            {
                type: "Background",
                value: "White",
            },
            {
                type: "Clothing",
                value: "Galactic Mercenary Outfit",
            },
            {
            type: "Head Item",
                value: "Pilot Helmet",
            },
            {
                type: "Weapon",
                value: "Crossbow",
            },
            {
                type: "Hair",
                value: "Straight Hair",
            },
            {
                type: "Eyes",
                value: "Blue",
            },
        ]
    },
    {
        id: 2,
        img: img2,
        title: 'King Of Pirates',
        attributes: [
            {
                type: "Faction",
                value: "'The Aethrin",
            },
            {
                type: "Background",
                value: "Black"
            },
            {
                type: "Clothing",
                value: "Armored Knight Suit"
            },
            {
                type: "Head Item",
                value: "Horned Helmet"
            },
            {
                type: "Weapon",
                value: "Greatsword"
            },
            {
                type: "Hair",
                value: "Braided Hair"
            },
            {
                type: "Eyes",
                value: "Red"
            }
        ]
    },
    {
        id: 3,
        img: img3,
        title: 'Pomeranian Doge',
          attributes: [
            {
                type: "Faction",
                value: "The Hundra",
            },
            {
                type: "Background",
                value: "Space"
            },
            {
                type: "Clothing",
                value: "Space Suit"
            },
            {
                type: "Head Item",
                value: "Space Helmet"
            },
            {
                type: "Weapon",
                value: "Laser Blaster"
            },
            {
                type: "Hair",
                value: "Bald"
            },
            {
                type: "Eyes",
                value: "Green"
            }
        ]
    },
    {
        id: 4,
        img: img4,
        title: '3D Digital Artwork',
           attributes: [
        {
        type: "Faction",
        value: "The Valerian",
    },
   {
      type: "Background",
      value: "Industrial"
    },
    {
      type: "Clothing",
      value: "Steampunk Outfit"
    },
    {
      type: "Head Item",
      value: "Goggles and Top Hat"
    },
    {
      type: "Weapon",
      value: "Steam-powered Gauntlet"
    },
    {
      type: "Hair",
      value: "Wild Curly Hair"
    },
    {
      type: "Eyes",
      value: "Brown"
    }
        ]
    },
    {
        id: 5,
        img: img5,
        title: 'Nintendo Switch',
           attributes: [
            {
                type: "Faction",
                value: "The Manrdakar",
            },
            {   
                type: "Background",
                value: "Moonlit"
            },
            {
                type: "Clothing",
                value: "Dark Rogue Attire"
            },
            {
                type: "Head Item",
                value: "Hood and Mask"
            },
            {
                type: "Weapon",
                value: "Dual Daggers"
            },
            {
                type: "Hair",
                value: "Slicked Back"
            },
            {
                type: "Eyes",
                value: "Silver"
            }
        ]
    },
    {
        id: 6,
        img: img6,
        title: 'The Strange Art',
           attributes: [
            {
                type: "Faction",
                value: "The Cavalore",
            },
            {
                type: "Background",
                value: "Nature"
            },
            {
                type: "Clothing",
                value: "Elven Ranger Outfit"
            },
            {
                type: "Head Item",
                value: "Leaf Crown"
            },
            {
                type: "Weapon",
                value: "Longbow"
            },
            {
                type: "Hair",
                value: "Flowing Wavy Hair"
            },
            {
                type: "Eyes",
                value: "Green"
            }
        ]
    },
    {
        id: 7,
        img: img7,
        title: 'Pixel Art Addicted',
           attributes: [
            {
                type: "Faction",
                value: "The Ur'Baal",
            },
            {
                type: "Background",
                value: "Neon City"
            },
            {
                type: "Clothing",
                value: "Cybernetic Ninja Gear"
            },
            {
                type: "Head Item",
                value: "Cyber Samurai Helmet"
            },
            {
                type: "Weapon",
                value: "Energy Katana"
            },
            {
                type: "Hair",
                value: "Shaved Head with Circuit Tattoos"
            },
            {
                type: "Eyes",
                value: "Purple"
             }   
        ]
    },
    {
        id: 8,
        img: img8,
        title: '3D Digital Artwork',
           attributes: [
            {
                type: "Faction",
                value: "The Valerian",
            },
            {
                type: "Background",
                value: "Library"
            },
            {
                type: "Clothing",
                value: "Robes of the Archmage"
            },
            {
                type: "Head Item",
                value: "Mage Hood"
            },
            {
                type: "Weapon",
                value: "Staff of Power"
            },
            {
                type: "Hair",
                value: "Long Flowing Hair"
            },
            {
                type: "Eyes",
                value: "Amber"
            }
        ]
    },
    {
        id: 9,
        img: img1,
        title: '3D Digital Artwork',
        attributes: [
            {
                type: "Faction",
                value: "The Valerian",
            },
            {
                type: "Background",
                value: "White",
            },
            {
                type: "Clothing",
                value: "Galactic Mercenary Outfit",
            },
            {
            type: "Head Item",
                value: "Pilot Helmet",
            },
            {
                type: "Weapon",
                value: "Crossbow",
            },
            {
                type: "Hair",
                value: "Straight Hair",
            },
            {
                type: "Eyes",
                value: "Blue",
            },
        ]
    },
    {
        id: 10,
        img: img2,
        title: 'King Of Pirates',
        attributes: [
            {
                type: "Faction",
                value: "'The Aethrin",
            },
            {
                type: "Background",
                value: "Black"
            },
            {
                type: "Clothing",
                value: "Armored Knight Suit"
            },
            {
                type: "Head Item",
                value: "Horned Helmet"
            },
            {
                type: "Weapon",
                value: "Greatsword"
            },
            {
                type: "Hair",
                value: "Braided Hair"
            },
            {
                type: "Eyes",
                value: "Red"
            }
        ]
    },
    {
        id: 11,
        img: img3,
        title: 'Pomeranian Doge',
          attributes: [
            {
                type: "Faction",
                value: "The Hundra",
            },
            {
                type: "Background",
                value: "Space"
            },
            {
                type: "Clothing",
                value: "Space Suit"
            },
            {
                type: "Head Item",
                value: "Space Helmet"
            },
            {
                type: "Weapon",
                value: "Laser Blaster"
            },
            {
                type: "Hair",
                value: "Bald"
            },
            {
                type: "Eyes",
                value: "Green"
            }
        ]
    },
    {
        id: 12,
        img: img4,
        title: '3D Digital Artwork',
           attributes: [
        {
        type: "Faction",
        value: "The Valerian",
    },
   {
      type: "Background",
      value: "Industrial"
    },
    {
      type: "Clothing",
      value: "Steampunk Outfit"
    },
    {
      type: "Head Item",
      value: "Goggles and Top Hat"
    },
    {
      type: "Weapon",
      value: "Steam-powered Gauntlet"
    },
    {
      type: "Hair",
      value: "Wild Curly Hair"
    },
    {
      type: "Eyes",
      value: "Brown"
    }
        ]
    },
    {
        id: 13,
        img: img5,
        title: 'Nintendo Switch',
           attributes: [
            {
                type: "Faction",
                value: "The Manrdakar",
            },
            {   
                type: "Background",
                value: "Moonlit"
            },
            {
                type: "Clothing",
                value: "Dark Rogue Attire"
            },
            {
                type: "Head Item",
                value: "Hood and Mask"
            },
            {
                type: "Weapon",
                value: "Dual Daggers"
            },
            {
                type: "Hair",
                value: "Slicked Back"
            },
            {
                type: "Eyes",
                value: "Silver"
            }
        ]
    },
    {
        id: 14,
        img: img6,
        title: 'The Strange Art',
           attributes: [
            {
                type: "Faction",
                value: "The Cavalore",
            },
            {
                type: "Background",
                value: "Nature"
            },
            {
                type: "Clothing",
                value: "Elven Ranger Outfit"
            },
            {
                type: "Head Item",
                value: "Leaf Crown"
            },
            {
                type: "Weapon",
                value: "Longbow"
            },
            {
                type: "Hair",
                value: "Flowing Wavy Hair"
            },
            {
                type: "Eyes",
                value: "Green"
            }
        ]
    },
    {
        id: 15,
        img: img7,
        title: 'Pixel Art Addicted',
           attributes: [
            {
                type: "Faction",
                value: "The Ur'Baal",
            },
            {
                type: "Background",
                value: "Neon City"
            },
            {
                type: "Clothing",
                value: "Cybernetic Ninja Gear"
            },
            {
                type: "Head Item",
                value: "Cyber Samurai Helmet"
            },
            {
                type: "Weapon",
                value: "Energy Katana"
            },
            {
                type: "Hair",
                value: "Shaved Head with Circuit Tattoos"
            },
            {
                type: "Eyes",
                value: "Purple"
             }   
        ]
    },
    {
        id: 16,
        img: img8,
        title: '3D Digital Artwork',
           attributes: [
            {
                type: "Faction",
                value: "The Valerian",
            },
            {
                type: "Background",
                value: "Library"
            },
            {
                type: "Clothing",
                value: "Robes of the Archmage"
            },
            {
                type: "Head Item",
                value: "Mage Hood"
            },
            {
                type: "Weapon",
                value: "Staff of Power"
            },
            {
                type: "Hair",
                value: "Long Flowing Hair"
            },
            {
                type: "Eyes",
                value: "Amber"
            }
        ]
    },

]

export default dataItem;