import DeepZoomArea from "../../lib/ReactOpenSeadragon/DeepZoomArea";
import { TiledImage } from "../../lib/ReactOpenSeadragon/TiledImage";
import React from "react";
import Hexes from "./Hexes";
import RoadMarkers from "./RoadMarkers";

export default function ZoomTest() {
  return (
    <DeepZoomArea
      style={{
        width: "100vw",
        height: "calc(100vh - 80px)",
      }}
      // enableDebugging={process.env.NODE_ENV === 'development'}
      options={{
        showNavigationControl: true,
        homeFillsViewer: true,
        constrainDuringPan: true,
        visibilityRatio: 1,
        maxZoomPixelRatio: 1.5,
        springStiffness: 200,
        animationTime: 20,
        zoomPerScroll: 1.3,
        zoomInButton: "zoomIn",
        zoomOutButton: "zoomOut",
        homeButton: "locationName",
        maxImageCacheCount: 999,
        showFullPageControl: false,
        // preserveImageSizeOnResize: true,
        preload: true,
        gestureSettingsMouse: {
          clickToZoom: false,
          dblClickToZoom: true,
        },
        gestureSettingsTouch: {
          clickToZoom: false,
          dblClickToZoom: true,
        },
        gestureSettingsPen: {
          clickToZoom: false,
          dblClickToZoom: true,
        },
        gestureSettingsUnknown: {
          clickToZoom: false,
          dblClickToZoom: true,
        },
      }}
    >
      <TiledImage
        options={{
          tileSource: "/tiles/dz/factionTiles.dzi",
        }}
      >
        <RoadMarkers />
        <Hexes />
      </TiledImage>
    </DeepZoomArea>
  );
}
