import React, { useEffect, useMemo } from "react";
import CollectionGrid from "../components/collectionGrid/CollectionGrid";
import { useDispatch, useSelector } from "react-redux";
import {
  getNftsByFilters,
  getNftsBySearchQuery,
} from "../redux/nfts/nftFiltersSelectors";
import { resetFilters } from "../redux/nfts/nftsSlice";
import SideKick from "../components/sidekick/SideKick";
import NftFilters from "../components/filter/Filters";

const Nfts = () => {
  const { items, filters, search } = useSelector((s) => s.nfts);
  const dispatch = useDispatch();

  const filteredNfts = useMemo(() => {
    const res = [];
    const filteredNfts = getNftsByFilters(items, filters);

    res.push(...filteredNfts);
    const source = res.length ? res : items;
    if (search) {
      const searchedNfts = getNftsBySearchQuery(source, search);
      return searchedNfts;
    }
    if (!filters.length && !search) {
      res.push(...items);
    }
    return res;
  }, [items, filters, search]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, [dispatch]);

  return (
    <div className="wrapper">
      <div className="container big">
        <div className="row py-5">
          <div className="col-md-12 col-lg-5 col-xl-3">
            <NftFilters />
          </div>
          <div className="col-md-12 col-lg-7 col-xl-9 filtered-results">
            <SideKick />
            {!filteredNfts.length ? (
              <h3>No NFT’s Found</h3>
            ) : (
              <CollectionGrid data={filteredNfts} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nfts;
