import { React, useEffect } from "react";
import AOS from "aos";
import { Route, Routes, useLocation } from "react-router-dom";

import routes from "./pages";
import Page404 from "./pages/404";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

const GAME_PATHNAME = '/game'

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const { pathname } = useLocation();

  return (
    <>
      <Header />
      <Routes>
        {routes.map((data, idx) => (
          <Route key={idx} path={data.path} element={data.component} exact />
        ))}
        <Route path="*" element={<Page404 />} />
      </Routes>
      {pathname !== GAME_PATHNAME && <Footer />} 
    </>
  );
};

export default App;
