import React, { useRef } from "react";
import useOpenSeadragonHandler from "../../lib/ReactOpenSeadragon/lib/hooks/useOpenSeadragonHandler";
import useDeepZoomArea from "../../lib/ReactOpenSeadragon/lib/hooks/useDeepZoomArea";

export default function DiamondMarker({
  className,
  modalTitle = "Home",
  style,
  onActiveMarker,
  idx,
}) {
  const markerImageRef = useRef(null);
  const markerButtonRef = useRef(null);
  const { viewer } = useDeepZoomArea();
  const zoomHandlerRef = useRef(() => {
    const zoom = viewer?.viewport?.getZoom();
    const newOpacity = zoom > 2.9 ? 1 : 0;
    if (newOpacity > 0) {
      markerButtonRef.current.style.visibility = "visible";
    } else {
      markerButtonRef.current.style.visibility = "hidden";
    }
    markerButtonRef.current.style.opacity = newOpacity;
    // const newImageSize = `${(100 / Math.round(zoom)) * 2}%`;
    // markerImageRef.current.style.height = newImageSize;
  }).current;
  useOpenSeadragonHandler("update-viewport", zoomHandlerRef);

  const handleClickOpen = () => {
    onActiveMarker(idx);
  };

  return (
    <>
      <button
        ref={markerButtonRef}
        type="button"
        className={`stoneBtn ${className || ""}`}
        onClick={handleClickOpen}
        style={style}
      >
        <img
          ref={markerImageRef}
          src="../../assets/images/stone-img2.png"
          alt={modalTitle || ""}
        />
      </button>
    </>
  );
}
