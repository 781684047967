import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CollectionGrid = ({ data }) => {
    return (
        <section className="nft">
            <div className="row">
                {
                    data.map(item => (
                        <div key={item.id} className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                            <div className="nft-item">
                                <div className="card-media">
                                    <Link to="#"><img src={item.img} alt="Cyfonii" /></Link>
                                </div>
                                <div className="card-title">
                                    <Link to="#" className="h5">{item.title}</Link>
                                </div>
                                <div className="card-bottom style-explode">
                                    <div className="price">
                                        <span className="icon-logo-01"></span>
                                        <div className="price-details">
                                            <span> Current Bid</span>
                                            <h6>0.45 ETH</h6>
                                        </div>
                                    </div>
                                    <div className="button-place-bid">
                                        <Link to="" data-toggle="modal" data-target="#popup_bid" className="sc-button"><span>Place Bid</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </section>
    );
}

CollectionGrid.propTypes = {
    data: PropTypes.array
};

export default CollectionGrid;