import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNftsAttributes } from "../../redux/nfts/nftFiltersSelectors";
import Filter from "./Filter";
import { resetFilters, search } from "../../redux/nfts/nftsSlice";
import { ReactComponent as ResetIcon } from "../../assets/images/i-repeat.svg";

const NftFilters = () => {
  const attributes = useSelector(getNftsAttributes);
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();

  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    dispatch(search(searchQuery));
  };

  const handleResetFilters = () => {
    setSearchQuery("");
    dispatch(resetFilters());
  };

  return (
    <div className="filter">
      <button
        type="button"
        className="action-btn action-btn--full-width mb-2"
        onClick={handleResetFilters}
      >
        <span>
          Reset Filters <ResetIcon className="reset-icon" />
        </span>
      </button>
      <div className="widget-search">
        <input
          type="text"
          placeholder="Search..."
          required=""
          onChange={handleSearchQuery}
          value={searchQuery}
        />
        <div className="btn-search">
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="9.7659"
              cy="9.76639"
              r="8.98856"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></circle>
            <path
              d="M16.0176 16.4849L19.5416 19.9997"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
      </div>
      <ul>
        {attributes.map((attr) => (
          <li key={attr.type}>
            <Filter type={attr.type} values={attr.values} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NftFilters;
