import React from "react";
import Button from "../button/Button";

const Banner = () => {
  return (
    <section className="banner banner--introduction s2">
      <div className="shape"></div>
      <div className="shape right"></div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <div className="block-text center">
              <h2 className="heading">Factions of Mora Ora</h2>
              <p className="mb-34">
                Connect to the lands of Mora Ora. Join the fight for your
                Faction and seek fate, wealth, and power in the land across the
                Spiraling Sea.
              </p>

              <Button title="Enter Mora Ora" link="/game" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
