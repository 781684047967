import React, { useState } from "react";

import img2 from "../../assets/images/about1.png";
import img3 from "../../assets/images/about2.jpeg";
import img4 from "../../assets/images/about3.png";
import img1 from "../../assets/images/about4.jpeg";
import img5 from "../../assets/images/about5.jpeg";

const About = () => {
  const [dataImg] = useState([
    {
      id: 1,
      img: img1,
      class: "img1",
    },
    {
      id: 2,
      img: img2,
      class: "img2",
    },
    {
      id: 3,
      img: img3,
      class: "img3",
    },
    {
      id: 4,
      img: img4,
      class: "img4",
    },
    {
      id: 5,
      img: img5,
      class: "img5",
    },
  ]);

  return (
    <section className="about" id="about">
      <div className="shape"></div>
      <div className="container">
        <div className="row rev">
          <div className="col-xl-6 col-md-12">
            <div className="about__right">
              <div className="images">
                {dataImg.map((idx) => (
                  <img
                    key={idx.id}
                    className={idx.class}
                    src={idx.img}
                    alt="cyfonii"
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="block-text">
              <h3 className="heading">About Mora Ora</h3>
              <p className="mb-17">
                Factions of Mora Ora is a collaborative tale of power, wealth,
                and magic, inspired by classic games such as Dungeons & Dragons
                and Settlers of Catan, and epics of the likes of Game of
                Thrones. 6 factions will fight for control of coveted resources
                on the newfound lands of Mora Ora. Each faction has unique
                characteristics and societies that users will take part in. Lead
                your faction to glory and reap the rewards!
              </p>
              <p className="mb-26">
                The story begins as a tale of adventure and war told
                collaboratively through art installments and virtual
                experiences. Participants can expect a narrative told via
                Twitter, and the website upon launch. The story will evolve
                dynamically with increasing levels of user participation. After
                mint, the interface will go live, granting holders access to the
                coveted lands with direct control and responsibility of their
                faction.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
