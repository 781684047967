import React from "react";
import Doc from "./Doc";

const DocsList = ({ data }) => {
  return (
    <section className="bloglist mb-5">
      <div className="container">
        <ul>
          {data.map((doc, idx) => (
            <li key={doc.id} className="flat-accordion mb-5">
              <Doc title={doc.title} img={doc.img} text={doc.text} idx={idx} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default DocsList;
