export const getItems = ({ nfts }) => {
  return nfts.items;
};

export const getFilters = ({ nfts }) => {
  return nfts.filters;
};

export const getNftsAttributes = ({ nfts }) => {
  const attributeMap = new Map();
  nfts.items.forEach((item) => {
    item.attributes.forEach((attribute) => {
      const existingAttribute = attributeMap.get(attribute.type);

      if (existingAttribute) {
        if (!existingAttribute.values.includes(attribute.value)) {
          existingAttribute.values.push(attribute.value);
        }
      } else {
        attributeMap.set(attribute.type, {
          type: attribute.type,
          values: [attribute.value],
        });
      }
    });
  });
  return Array.from(attributeMap.values());
};

export const getNftsByFilters = (nfts, filters) => {
  const groupedFilters = filters.reduce((acc, curr) => {
    const group = acc.find((el) => el.type === curr.type);
    if (group) {
      group.values.push(curr.value);
    } else {
      acc.push({
        type: curr.type,
        values: [curr.value],
      });
    }
    return acc;
  }, []);

  return filters.length ? nfts.filter((el) => {
    return groupedFilters.every((group) => {
      const { type } = group;
      const elAttr = el.attributes.find((attr) => attr.type === type);
      return group.values.includes(elAttr.value);
    }) 
  }) : [];
};

export const getNftsBySearchQuery = (nfts, searchQuery) =>
  nfts.filter((nft) =>
    nft.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

export const getFiltedNfts = (state) => {
  const nftsItems = getItems(state);
  const nftFilters = getFilters(state);
  const searchQuery = state.nfts.search;
  const res = [];
  const filteredNfts = getNftsByFilters(nftsItems, nftFilters);
  res.push(...filteredNfts);
  const source = res.length ? res : nftsItems;
  if (searchQuery) {
    const searchedNfts = getNftsBySearchQuery(source, searchQuery);
    return searchedNfts;
  }
  if (!nftFilters.length && !searchQuery) {
    res.push(...nftsItems);
  }
  return res;
};
