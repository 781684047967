const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
    namesub: [
      {
        id: 1,
        sub: "About",
        links: "#about",
      },
      {
        id: 2,
        sub: "Collection",
        links: "#collection",
      },
      {
        id: 3,
        sub: "Roadmap",
        links: "#roadmap",
      },
      {
        id: 4,
        sub: "Team",
        links: "#team",
      },
      {
        id: 5,
        sub: "FAQ",
        links: "#faq",
      },
    ],
  },
  {
    id: 2,
    name: "Lore",
    links: "/lore",
  },
  {
    id: 4,
    name: "Docs",
    links: "/docs",
  },
  {
    id: 5,
    name: "Collections",
    links: "/gallery",
  },
];

export default menus;
