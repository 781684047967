import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap-accordion";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addFilter, removeFilter } from "../../redux/nfts/nftsSlice";
import { getFilters } from "../../redux/nfts/nftFiltersSelectors";
import { filtersIcons } from "../../utils/filterIcons";

const Filter = ({ values, type }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredValues, setFilteredValues] = useState(values);
  const [acoordionCardMinHeight, setAccordionCardMinHeight] = useState(null);
  const filterItemRef = useRef(null);
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);

  const handleCheckboxChange = (attrValue) => {
    const isChecked = filters.some(
      (filter) => filter.value === attrValue && type === filter.type
    );
    // const action = isChecked ? removeFilter : addFilter;
    if (isChecked) {
      dispatch(removeFilter({ type, value: attrValue }));
    } else {
      dispatch(addFilter([{ type, value: attrValue }]));
    }
  };

  const handleSearchTermChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getActiveFilters = (value, type) =>
    filters.find(({ value: fv, type: ft }) => fv === value && ft === type);

  const getFilterGroupIcon = (type) =>
    filtersIcons.find((el) => el.type === type)?.icon;

  useEffect(() => {
    if (searchQuery.length >= 2) {
      const filteredValues = values.filter((value) =>
        value.toLowerCase().includes(searchQuery.toLocaleLowerCase())
      );
      setFilteredValues(() => filteredValues);
    }
    if (!searchQuery.length) {
      setFilteredValues(() => values);
    }
  }, [searchQuery, values]);

  useEffect(() => {
    if (filterItemRef.current) {
      const filterHeight = filterItemRef?.current?.clientHeight || 1;
      setAccordionCardMinHeight(filterHeight * values.length);
    }
  }, [values.length]);

  return (
    <div className="flat-accordion">
      <Accordion
        key={type}
        title={
          <div className="accordion-button__title">
            {getFilterGroupIcon(type)} <span>{type}</span>
          </div>
        }
        show={!!filters.find((filter) => filter.type === type)}
      >
        <div className="widget-search">
          <input
            type="text"
            placeholder="Search..."
            required=""
            onChange={handleSearchTermChange}
          />
          <div className="btn-search">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="9.7659"
                cy="9.76639"
                r="8.98856"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></circle>
              <path
                d="M16.0176 16.4849L19.5416 19.9997"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
        </div>
        <Card
          className="accordin-card"
          style={{ minHeight: acoordionCardMinHeight }}
        >
          <ul className="filter-values">
            {filteredValues.length > 0 &&
              filteredValues.map((value) => (
                <li
                  key={value}
                  className={`filter-values__item${
                    getActiveFilters(value, type)
                      ? " filter-values__item--active"
                      : ""
                  }`}
                  ref={filterItemRef}
                >
                  <label>
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(value)}
                    />
                    {value}
                  </label>
                </li>
              ))}
          </ul>
        </Card>
      </Accordion>
    </div>
  );
};

export default Filter;
