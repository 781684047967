import useDeepZoomArea from './useDeepZoomArea';
import useOpenSeadragonHandler from './useOpenSeadragonHandler';
import { useCallback } from 'react';

export default function useOpenSeadragonClickHandler(image, onClick) {
  const { viewer } = useDeepZoomArea();

  const handler = useCallback(
    (event) => {
      if (!image) return;
      const viewportPoint = viewer?.viewport.pointFromPixel(event?.position);
      const imagePoint = image?.viewportToImageCoordinates(viewportPoint);
      const { x: width, y: height } = image.getContentSize();

      if (imagePoint.x < 0) return;
      if (imagePoint.y < 0) return;
      if (imagePoint.x > width) return;
      if (imagePoint.y > height) return;
      onClick?.({ position: imagePoint, imageSize: { height, width } });
    },
    [image, viewer, onClick]
  );

  useOpenSeadragonHandler('canvas-click', handler);
}
