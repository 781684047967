import assest from "../../assets/map/index";
import React, { useRef } from "react";
import { Button, Grid, Slide } from "@mui/material";
import ModalWrapperThree from "../modals/ModalWrapperThree";
import { useToggle } from "../../hooks/useToggle";
import Fade from "@mui/material/Fade";
import ZoomTest from "./Zoom";

const RestingAtHome = ({ open, toggle }) => {
  const containerRef = useRef(null);

  return (
    <div className="character">
      <div onClick={toggle} className="mainCharater">
        {/* <div className={styles.resting}>
          <span>Resting at home</span>
          <span>+2 STAMINA</span>
        </div> */}

        <Button>
          <figure className="avatarImg">
            <img src={assest.avtImg} alt="button" height={80} width={80} />
          </figure>
          <img
            src={assest.buttonBackground02}
            alt="button"
            height={58}
            width={245}
            className="btnbgImg"
          />
          <p>CHARACTER NAME</p>
          <div className="characterProgressBar">
            <span
              style={{
                display: "inline-block",
                width: "100%",
                background: "url(/assets/images/fillcolor.png) no-repeat",
                position: "absolute",
                left: "0",
              }}
            />
            <label>100%</label>
          </div>
        </Button>
        {/* <div className="blackbtnsGroup">
          <div className="frameBtn">
            <figure>
              <img
                src={assest.blackFrame}
                alt="button"
                height={50}
                width={50}
              />
            </figure>
            <b>15</b>
          </div>
          <div className="frameBtn">
            <figure>
              <img
                src={assest.blackFrame}
                alt="button"
                height={50}
                width={50}
              />
            </figure>
            <b>15</b>
          </div>
          <div className="frameBtn">
            <figure>
              <img
                src={assest.blackFrame}
                alt="button"
                height={50}
                width={50}
              />
            </figure>
            <b>15</b>
          </div>
        </div> */}
      </div>
      <Fade
        in={open}
        timeout={{
          exit: 1000,
        }}
      >
        <div className="subCharacters" ref={containerRef}>
          <Slide
            direction="up"
            in={open}
            container={containerRef.current}
            timeout={{
              enter: 800,
              exit: 500,
            }}
            unmountOnExit
            mountOnEnter
          >
            <div className="mainCharater">
              <Button>
                <figure className="avatarImg">
                  <img
                    src={assest.avtImg}
                    alt="button"
                    height={68}
                    width={68}
                  />
                  <div className="charNumber">60</div>
                </figure>
                <img
                  src={assest.buttonBackground02}
                  alt="button"
                  height={50}
                  width={208}
                  className="btnbgImg"
                />
                <p>CHARACTER NAME</p>
                <div className="characterProgressBar">
                  <span
                    style={{
                      display: "inline-block",
                      width: "100%",
                      background: "url(/assets/images/fillcolor.png) no-repeat",
                      position: "absolute",
                      left: "0",
                    }}
                  />
                  <label>100%</label>
                </div>
              </Button>
            </div>
          </Slide>
          <Slide
            direction="up"
            in={open}
            container={containerRef.current}
            timeout={{
              enter: 800,
              exit: 500,
            }}
            unmountOnExit
            mountOnEnter
          >
            <div className="mainCharater">
              <Button>
                <figure className="avatarImg">
                  <img
                    src={assest.avtImg}
                    alt="button"
                    height={68}
                    width={68}
                  />
                  <div className="charNumber">60</div>
                </figure>
                <img
                  src={assest.buttonBackground02}
                  alt="button"
                  height={50}
                  width={208}
                  className="btnbgImg"
                />
                <p>CHARACTER NAME</p>
                <div className="characterProgressBar">
                  <span
                    style={{
                      display: "inline-block",
                      width: "100%",
                      background: "url(/assets/images/fillcolor.png) no-repeat",
                      position: "absolute",
                      left: "0",
                    }}
                  />
                  <label>100%</label>
                </div>
              </Button>
            </div>
          </Slide>
          <Slide
            direction="up"
            in={open}
            container={containerRef.current}
            timeout={{
              enter: 500,
              exit: 500,
            }}
            unmountOnExit
            mountOnEnter
          >
            <div className="mainCharater">
              <Button>
                <figure className="avatarImg">
                  <img
                    src={assest.avtImg}
                    alt="button"
                    height={68}
                    width={68}
                  />
                  <div className="charNumber">60</div>
                </figure>
                <img
                  src={assest.buttonBackground02}
                  alt="button"
                  height={50}
                  width={208}
                  className="btnbgImg"
                />
                <p>CHARACTER NAME</p>
                <div className="characterProgressBar">
                  <span
                    style={{
                      display: "inline-block",
                      width: "100%",
                      background: "url(/assets/images/fillcolor.png) no-repeat",
                      position: "absolute",
                      left: "0",
                    }}
                  />
                  <label>100%</label>
                </div>
              </Button>
            </div>
          </Slide>
        </div>
      </Fade>
    </div>
  );
};
const boxloop = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export default function Game() {
  const [openItems, toggleItems] = useToggle(false);
  const [open3, setOpen3] = React.useState(false);
  const handleClickOpen3 = () => {
    setOpen3(!open3);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  //   const [loaded, setLoaded] = useState(false);

  return (
    <div className="main_page main_page__game" draggable={false}>
      {/* <Loader open={!loaded} fullScreen /> */}
      <div>
        <div className="location">
          <div className="locBtn">
            <img src={assest.locationBg} alt="button" height={83} width={288} />
            <div id="locationName" />
            <Button id="zoomIn" className="btnCom plusBtn">
              +
            </Button>
            <Button id="zoomOut" className="btnCom minusBtn">
              -
            </Button>
          </div>
        </div>
        <div className="inventory">
          <Button onClick={handleClickOpen3}>
            <img
              src={assest.buttonBackground}
              alt="button"
              height={83}
              width={288}
            />
            <p>Inventory</p>
          </Button>
          <ModalWrapperThree open={open3} onClose={handleClose3} title="Home 3">
            <Grid container spacing={2}>
              {boxloop.map((item) => {
                return (
                  <Grid item xs={4} key={item}>
                    <div className="invent_box" />
                  </Grid>
                );
              })}
            </Grid>
          </ModalWrapperThree>
        </div>

        <RestingAtHome open={openItems} toggle={toggleItems} />
        <ZoomTest />
      </div>
    </div>
  );
}
