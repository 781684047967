import React, { useState } from "react";
import { Accordion } from "react-bootstrap-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import img from "../../assets/images/faq.png";

const FAQ = ({ data }) => {

  const [dataTab, setTab] = useState([
    {
      id: 1,
      title: "General",
      selected: true,
    },
    {
      id: 2,
      title: "Game",
      selected: false,
    },
  ]);

  const onSwitch = (idx) => {
    const mappedTabs = dataTab.map((t) => {
      t.selected = t.id !== idx ? false : true;
      return t;
    });
    setTab(() => mappedTabs);
  };

  return (
    <section className="faq s3" id="faq">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <div className="block-text">
              <h3 className="heading">Frequently Asked Questions</h3>
            </div>
            <div className="faq__main flat-tabs">
              <Tabs>
                <TabList className="menu-tab">
                  {dataTab.map((tab) => (
                    <Tab
                      className="fs-14 h6"
                      key={tab.id}
                      onClick={() => onSwitch(tab.id)}
                    >
                      {tab.title}
                    </Tab>
                  ))}
                </TabList>

                {dataTab.map((tab) => (
                  <TabPanel key={tab.id} className="content-tab">
                    <div className="content-inner">
                      <div className="flat-accordion">
                        {data
                          .filter((d) =>
                            tab.selected ? tab.title === d.category : false
                          )
                          .map((tabData) => (
                            <Accordion
                              show={tabData.show}
                              key={tabData.id}
                              title={tabData.title}
                              className="flat-toggle h6"
                            >
                              <p className="toggle-content">{tabData.text} </p>
                            </Accordion>
                          ))}
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </Tabs>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div
              className="image"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <img src={img} alt="Cyfonii" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
