import { SimpleOverlay } from "../../lib/ReactOpenSeadragon/Overlay";
import useDeepZoomArea from "../../lib/ReactOpenSeadragon/lib/hooks/useDeepZoomArea";
import OpenSeadragon from "openseadragon";
import React, { useCallback, useId } from "react";
import HexPositionOverlay from "./HexPositionOverlay";

const idSuffix = `${Math.random() * 1000}Boundary`;

export default function BoundaryHex({
  rectangle = { x: 0, y: 0, width: 0, height: 0 },
  viewportCoordinates,
  style,
  className,
  name,
  zoomFitOffset = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
}) {
  const { viewer, debugEnabled } = useDeepZoomArea();
  const elementId = useId() + idSuffix;
  const onClick = useCallback(() => {
    const newRect = new OpenSeadragon.Rect(
      rectangle.x + rectangle.width / 2,
      rectangle.y + rectangle.height / 2,
      rectangle.width,
      rectangle.height
    );

    const vpRect = viewer.viewport.imageToViewportRectangle(newRect);
    const vpPos = viewer.viewport.imageToViewportCoordinates(
      newRect.x,
      newRect.y
    );
    //auto assign
    if (zoomFitOffset.width && !zoomFitOffset.x) {
      zoomFitOffset.x = -zoomFitOffset.width / 2;
    }
    if (zoomFitOffset.height && !zoomFitOffset.y) {
      zoomFitOffset.y = -zoomFitOffset.height / 2;
    }
    viewer.viewport.fitBoundsWithConstraints(
      new OpenSeadragon.Rect(
        vpPos.x - vpRect.width / 2 + (zoomFitOffset?.x || 0),
        vpPos.y - vpRect.height / 2 + (zoomFitOffset?.y || 0),
        vpRect.width + (zoomFitOffset?.width || 0),
        vpRect.height + (zoomFitOffset?.height || 0)
      )
    );
  }, [viewer, rectangle, zoomFitOffset]);

  const ClickableArea = (
    <div
      className={className}
      id={elementId}
      style={{
        width: "100%",
        height: "100%",
        cursor: "pointer",
        zIndex: 1,
        position: "relative",
        ...style,
        ...(debugEnabled
          ? {
              backgroundColor: "rgba(255, 0, 0, 0.4)",
              border: "1px solid red",
            }
          : {}),
      }}
    >
      {name ? <HexPositionOverlay name={name} /> : null}
    </div>
  );

  let child = ClickableArea;
  if (debugEnabled) {
    child = (
      <div
        style={{
          width: "100%",
          height: "100%",
          zIndex: 1,
          position: "relative",
          border: "1px solid red",
        }}
      >
        {ClickableArea}
      </div>
    );
  }
  return (
    <SimpleOverlay
      rectangle={rectangle}
      viewportCoordinates={viewportCoordinates}
      clickTarget={elementId}
      onClick={onClick}
      placement={OpenSeadragon.Placement.CENTER}
    >
      {child}
    </SimpleOverlay>
  );
}
