import React, { useState } from "react";

import { Link, useLocation } from "react-router-dom";
import menus from "../../pages/menu";
import logo from "../../assets/images/landing-logo.svg";
import { ReactComponent as IconDiscord } from "../../assets/images/discordIcon.svg";
import { ReactComponent as IconTwitter } from "../../assets/images/twitter.svg";
import { ReactComponent as IconOpensea } from "../../assets/images/new-boat.svg";
import { Scrollchor } from "react-scrollchor";
import { ROUTE_PATH } from "../../constants/routes";

const socialIcons = [
  {
    id: 1,
    item: (
      <IconDiscord
        style={{
          color: "white",
        }}
      />
    ),
    url: "/",
  },
  {
    id: 2,
    item: (
      <IconTwitter
        style={{
          color: "white",
        }}
      />
    ),
    url: "/",
  },
  {
    id: 3,
    item: (
      <IconOpensea
        style={{
          color: "white",
        }}
      />
    ),
    url: "/",
  },
];

const Header = () => {
  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex((prev) => {
      if (prev === index) {
        return null;
      }
      return index;
    });
  };

  const { pathname } = useLocation();

  return (
    <header id="header_main" className="header">
      <div className="container big">
        <div className="row">
          <div className="col-12">
            <div className="header__body">
              <div className="header__logo">
                <Link to={ROUTE_PATH.HOME} className="logo">
                  <img src={logo} alt="Faction of mora ora" />
                </Link>
              </div>

              <div className="header__right">
                <nav
                  id="main-nav"
                  className={`main-nav ${menuActive ? "active" : ""}`}
                >
                  <ul id="menu-primary-menu" className="menu">
                    {menus.map((data, idx) => (
                      <li
                        key={idx}
                        onClick={() => handleDropdown(idx)}
                        className={`menu-item ${
                          data.namesub && pathname === ROUTE_PATH.HOME
                            ? "menu-item-has-children"
                            : ""
                        } ${activeIndex === idx ? "active" : ""}`}
                      >
                        <Link to={data.links}>{data.name}</Link>
                        {data.namesub && pathname === ROUTE_PATH.HOME && (
                          <ul className="sub-menu">
                            {data.namesub.map((submenu) => (
                              <li key={submenu.id} className="menu-item">
                                <Scrollchor
                                  to={submenu.links}
                                  animate={{ offset: -100 }}
                                >
                                  {submenu.sub}
                                </Scrollchor>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                  <div className="header__action">
                    <ul className="list-social--header">
                      {socialIcons.map((icon) => (
                        <li key={icon.id}>
                          <Link to={icon.url}>{icon.item}</Link>
                        </li>
                      ))}
                    </ul>
                    {pathname !== ROUTE_PATH.GAME ? (
                      <Link to="/game" className="action-btn">
                        <span>Enter Mora Ora</span>
                      </Link>
                    ) : (
                      <button className="action-btn action-btn--wallet-connect">
                        <span>Connect wallet</span>
                      </button>
                    )}
                  </div>
                </nav>
                <div
                  className={`mobile-button ${menuActive ? "active" : ""}`}
                  onClick={handleMenuActive}
                >
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
