import img1 from '../images/layouts/team-01.png'
import img2 from '../images/layouts/team-02.png'
import img3 from '../images/layouts/team-03.png'
import img4 from '../images/layouts/team-04.png'
import img5 from '../images/layouts/team-05.png'
import img6 from '../images/layouts/team-06.png'
import img7 from '../images/layouts/team-07.png'
import img8 from '../images/layouts/team-08.png'

const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'Reed',
        position: 'Cofounder',
        twitterUrl: 'https://twitter.com/reedwalchle',
        instagramUrl: "https://www.instagram.com/rwpho/",
    },
    {
        id: 2,
        img: img2,
        name: 'ChargeUp33',
        position: 'Cofounder',
        twitterUrl: 'https://twitter.com/Sam_S35',
        instagramUrl: ""
    },
    {
        id: 3,
        img: img3,
        name: 'Elena',
        position: 'Cofounder',
        twitterUrl: '',
        instagramUrl: ""
    },
    {
        id: 4,
        img: img4,
        name: 'Kandle',
        position: 'Marketing',
        twitterUrl: 'https://twitter.com/KandlePlug',
        instagramUrl: ""
    },
    {
        id: 5,
        img: img5,
        name: 'Rachel',
        position: 'Art',
        twitterUrl: 'https://twitter.com/RachWhitingArt',
        instagramUrl: ""
    },
    {
        id: 6,
        img: img6,
        name: 'Katerina',
        position: 'Art',
        twitterUrl: '',
        instagramUrl: "https://www.instagram.com/vitkovskaya_art/"
    },
    {
        id: 7,
        img: img7,
        name: 'Thomas',
        position: 'Marketing',
        twitterUrl: 'https://twitter.com/techezabal',
        instagramUrl: ""
    },
    {
        id: 8,
        img: img8,
        name: 'Nida',
        position: 'Art',
        twitterUrl: '',
        instagramUrl: ""
    },
]

export default dataTeam;