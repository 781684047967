import { useCallback, useEffect, useMemo } from 'react';
import useCallbackRef from './useCallbackRef';
import useDeepZoomArea from './useDeepZoomArea';

export default function useOpenSeadragonHandler(
  eventName,
  handler,
  options,
  propsViewer
) {
  const { viewer } = useDeepZoomArea();
  const handlerRef = useCallbackRef(handler);

  const handlerAction = useCallback(
    (e) => {
      handlerRef.current?.(e);
    },
    [handlerRef]
  );

  const determinedViewer = useMemo(() => {
    return propsViewer || viewer;
  }, [propsViewer, viewer]);

  useEffect(() => {
    if (options?.once) {
      determinedViewer?.addOnceHandler(eventName, handlerAction);
    } else {
      determinedViewer?.addHandler(eventName, handlerAction);
    }
    return () => {
      determinedViewer?.removeHandler?.(eventName, handlerAction);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName, handlerAction, determinedViewer]);
}
