import OpenSeadragon from "openseadragon";
import { createPortal } from "react-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import useDeepZoomArea from "./lib/hooks/useDeepZoomArea";
import { useTiledImage } from "./TiledImage";
import useCallbackRef from "./lib/hooks/useCallbackRef";
import usePreventClickRef from "./lib/hooks/usePreventClickRef";

//overlay hack
class ExtendedOverlay {
  listeners = ["animation", "open", "resize", "rotate"];

  preventClick = false;

  constructor(viewer, options) {
    this._viewer = viewer;
    this._options = options;

    this._element = document.createElement("div");
    this._element.style.position = "absolute";
    this._element.style.left = 0;
    this._element.style.top = 0;
    // We give it width and height of 0 so it doesn't steal events from the viewer.
    this._element.style.width = 0;
    this._element.style.height = 0;
    if (typeof this?._options?.zIndex !== "undefined") {
      this._element.style.zIndex = this?._options?.zIndex;
    }
    this._element.style.transformOrigin = "0 0";
    this._viewer.canvas.appendChild(this._element);
    this._scale = this._options.scale;
    this._viewer.addHandler("canvas-drag", this.onDragCanvas.bind(this));
    this.listeners.forEach((event) => this.bindListener(event));
    this.onClick();
    this.resize();
  }

  element() {
    return this._element;
  }

  enableClick() {
    this.preventClick = false;
    document.removeEventListener("click", this.enableClick.bind(this));
  }

  onDragCanvas() {
    if (!this.preventClick) {
      this.preventClick = true;
      document.addEventListener("click", this.enableClick.bind(this));
    }
  }

  addOverlay(element) {
    this._element.appendChild(element);
    return this;
  }

  bindListener(event) {
    this._viewer.addHandler(event, this.resize.bind(this));
    return this;
  }

  removeListener(event) {
    this._viewer.removeHandler(event, this.resize.bind(this));
    return this;
  }

  // ----------
  resize() {
    const p = this._viewer.viewport.pixelFromPoint(
      new OpenSeadragon.Point(0, 0),
      true
    );
    const zoom = this._viewer.viewport.getZoom(true);
    const rotation = this._viewer.viewport.getRotation();

    // TODO: Expose an accessor for _containerInnerSize in the OSD API so we don't have to use the private variable.
    const scale =
      (this._viewer.viewport._containerInnerSize.x * zoom) / this._scale;

    this._element.style.transform = `translate(${p.x}px,${p.y}px) scale(${scale}) rotate(${rotation})`;
    return this;
  }

  // ----------
  onClick() {
    // OpenSeadragon blocks the normal click event action, so we have to reestablish it for links here
    this._clickTracker = new OpenSeadragon.MouseTracker({
      element: this._element,
      clickHandler: this._handleClicks.bind(this),
    });

    // // TODO: Fast click for mobile browsers
    // new OpenSeadragon.MouseTracker({
    //   element,
    //   clickHandler: handler
    // }).setTracking(true);
    return this;
  }

  _handleClicks(event) {
    // The event.originalTarget is the new OSD way; we're keeping
    // the event.originalEvent.target fallback for old OSD.
    if (this.preventClick) return;
    const clickTarget = event.originalTarget || event.originalEvent.target;
    if (clickTarget.nodeName?.toLowerCase() === "a") {
      if (clickTarget.target === "_blank") {
        window.open(clickTarget.href);
      } else {
        window.location.href = clickTarget.href;
      }
    }
    clickTarget?.click?.(event?.originalEvent);
    this._options?.onClick?.(event);
  }

  destroy() {
    this?._clickTracker?.destroy?.();
    this.listeners.forEach((event) => this.removeListener(event));
    this._viewer.removeHandler("canvas-drag", this.onDragCanvas.bind(this));
  }
}

export function ScaledOverlay({ children, scale = 1000, onClick, zIndex }) {
  const { viewer } = useDeepZoomArea();
  const [element] = useState(document.createElement("div"));

  const onClickRef = useCallbackRef(onClick);
  const clickHandler = useCallback(
    (e) => {
      onClickRef.current?.(e);
    },
    [onClickRef]
  );

  useEffect(() => {
    let overlay = null;
    if (viewer && element) {
      overlay = new ExtendedOverlay(viewer, {
        scale,
        onClick: clickHandler,
        zIndex,
      });
      overlay.addOverlay?.(element);
    }
    return () => {
      overlay?.destroy?.();
    };
  }, [viewer, clickHandler, element, scale, zIndex]);

  return createPortal(children, element);
}

export function convertImageToViewportCoordinate(rectangle, image) {
  // change from image coordinate system to viewport coordinate system
  if (!image) return null;
  return image.imageToViewportRectangle(
    new OpenSeadragon.Rect(
      rectangle.x,
      rectangle.y,
      rectangle.width,
      rectangle.height
    )
  );
}

export function SimpleOverlay({
  placement = OpenSeadragon.Placement.CENTER,
  rectangle = { x: 0, y: 0, width: 0, height: 0 },
  viewportCoordinates,
  children,
  onClick,
  clickTarget,
}) {
  const { viewer } = useDeepZoomArea();
  const { image, inImage } = useTiledImage();
  const [element] = useState(document.createElement("div"));

  const { preventClickRef } = usePreventClickRef();
  const onClickRef = useCallbackRef(onClick);
  const clickHandler = useCallback(
    (e) => {
      if (preventClickRef.current) return;
      onClickRef.current?.(e);
    },
    [onClickRef, preventClickRef]
  );

  const viewportRectangle = useMemo(() => {
    return inImage && !viewportCoordinates
      ? convertImageToViewportCoordinate(rectangle, image)
      : new OpenSeadragon.Rect(
          rectangle.x,
          rectangle.y,
          rectangle.width,
          rectangle.height
        );
  }, [inImage, viewportCoordinates, rectangle, image]);

  useEffect(() => {
    viewer?.addOverlay(element, viewportRectangle, placement);
    return () => {
      viewer?.removeOverlay?.(element);
    };
  }, [viewer, element, viewportRectangle, placement]);

  useEffect(() => {
    const clickTracker = new OpenSeadragon.MouseTracker({
      element: clickTarget || element,
      clickHandler,
    });
    return () => {
      clickTracker?.destroy?.();
    };
  }, [clickTarget, clickHandler, element]);

  useEffect(() => {
    viewer?.updateOverlay?.(element, viewportRectangle, placement);
  }, [viewer, placement, viewportRectangle, element]);

  return createPortal(children, element);
}

export function Control({
  position = OpenSeadragon.ControlAnchor.TOP_LEFT,
  autoFade,
  children,
}) {
  const [containerEl] = useState(document.createElement("div"));
  const { viewer } = useDeepZoomArea();

  useEffect(() => {
    viewer?.addControl(containerEl, {
      anchor: position,
      autoFade,
    });
    return () => {
      viewer?.removeControl(containerEl);
    };
  }, [autoFade, containerEl, position, viewer]);

  return createPortal(children, containerEl);
}
