import React, { useEffect, useRef } from "react";
import LoreItem from "./LoreItem";
import PropTypes from "prop-types";
const LoreList = ({ docs, quote }) => {
  const quoteRef = useRef(null);

  useEffect(() => {
    if (quoteRef && quote) {
      quoteRef.current.innerHTML = quote;
    }
  }, [quote]);

  return (
    <>
      {quote && <div ref={quoteRef} className="mb-5 quote"></div>}
      {docs.map((doc, idx) => (
        <LoreItem
          key={doc.id}
          img={doc.img}
          title={doc.title}
          text={doc.text}
          quote={doc.quote}
        />
      ))}
    </>
  );
};

LoreList.prototype = {
  docs: PropTypes.array.isRequired,
  quote: PropTypes.string,
};

export default LoreList;
