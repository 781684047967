import React from "react";
import { Link } from "react-router-dom";
import line from "../../assets/images/background/line-2.png";
import { ReactComponent as InstagramIcon } from '../../assets/images/svg/instagram.svg';

const Team = ({ data }) => {
  return (
    <section className="team s2" id="team">
      <div className="shape right"></div>
      <img src={line} alt="" className="img-line" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="block-text center">
              <h3 className="heading wow" data-splitting>
                Our Team
              </h3>
            </div>
          </div>
          {data.map((entry) => (
            <div key={entry.id} className="col-xl-3 col-md-6">
              <div className="team-box">
                <div className="image">
                  <Link to="/team">
                    <img src={entry.img} alt="Cyfonii" />
                  </Link>

                  <ul className="list-social">
                    <li>
                      <Link to={entry.twitterUrl}>
                        <span className="icon-twiter"></span>
                      </Link>
                    </li>
                    {entry.instagramUrl && <li>
                      <Link to={entry.instagramUrl}>
                        <InstagramIcon className="instagram" />
                      </Link>
                    </li>}
                  </ul>
                </div>
                <div className="content">
                  <Link to="/team" className="h5 name">
                    {entry.name}
                  </Link>
                  <p className="postion">{entry.position}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
