/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useEffect, useState, createContext, useMemo, useContext } from 'react';
import useOpenSeadragonClickHandler from './lib/hooks/useOpenSeadragonClickHandler';
import useDeepZoomArea from './lib/hooks/useDeepZoomArea';

export const TiledImageContext = createContext({ inImage: false });
export const TiledImageConsumer = TiledImageContext.Consumer;
export function TiledImage({ onClick, children, options, loader }) {
  const { viewer } = useDeepZoomArea();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setImage(null);
    const promise = new Promise((resolve, reject) => {
      viewer?.addTiledImage?.({
        success: resolve,
        failure: reject,
        ...options
      });
    })
      .then(({ item }) => {
        setImage(item);
        return item;
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 700);
      });

    return () => {
      promise.then((tiledImage) => {
        viewer.world.removeItem(tiledImage);
        setImage((currentImage) => {
          if (currentImage === tiledImage) {
            return null;
          } else {
            return currentImage;
          }
        });
      });
    };
  }, [viewer, JSON.stringify(options)]);

  useOpenSeadragonClickHandler(image, onClick);

  const contextValue = useMemo(
    () => ({ image, inImage: Boolean(image), loading }),
    [image, loading]
  );
  return (
    <TiledImageContext.Provider value={contextValue}>
      {image ? children || null : null}
      {loading ? loader || null : null}
    </TiledImageContext.Provider>
  );
}

export function useTiledImage() {
  return useContext(TiledImageContext);
}
