import React from "react";
import img from "../../assets/images/background/line.png";

const Roadmap = ({ data }) => {
  return (
      <section className="roadmap" id="roadmap">
        <img src={img} alt="" className="img-line" />
        <div className="shape"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="block-text center">
                <h3 className="heading pd">
                  Roadmap
                </h3>
              </div>

              <div
                className="roadmap__main"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                {data.map((entry) => (
                  <div key={entry.id} className={`roadmap-box ${entry.class}`}>
                    <div className="content">
                      <h5 className="title">{entry.id}. {entry.title}</h5>
                      <p className="text">{entry.desc}</p>
                    </div>
                  </div>
                ))}
                <div className="icon"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

export default Roadmap;
