/* eslint-disable import/no-anonymous-default-export */
export default {
  logo: '/assets/images/logo.svg',
  homeBg: '/assets/images/landing-page-background.jpg',
  newHomeBg5: '/assets/images/new-landing-5.jpg',
  newHomeBg10: '/assets/images/new-landing-10.jpg',
  newHomeBg25: '/assets/images/new-landing-25.jpg',
  newHomeBg50: '/assets/images/new-landing-50.jpg',
  newHomeBg75: '/assets/images/new-landing-75.jpg',
  newHomeBg90: '/assets/images/new-landing-90.jpg',
  newHomeBg: '/assets/images/new-landing.jpg',
  discord: '/assets/images/discord.svg',
  twitter: '/assets/images/twitter.svg',
  logomark: '/assets/images/logomark.svg',
  gallery: '/assets/images/heading-gallery.svg',
  lore: '/assets/images/lore.svg',
  team: '/assets/images/team.svg',
  bannerimage: '/assets/images/bannerimage.png',
  lorebanner: '/assets/images/lore-banner.png',
  star: '/assets/images/favourites.svg',
  search: '/assets/images/i-search.svg',
  acc01: '/assets/images/i-type.svg',
  acc02: '/assets/images/i-special.svg',
  acc03: '/assets/images/i-clothing.svg',
  acc04: '/assets/images/i-off-hand.svg',
  acc05: '/assets/images/i-hair.svg',
  acc06: '/assets/images/i-headgear.svg',
  acc07: '/assets/images/i-face.svg',
  acc08: '/assets/images/i-neck.svg',
  acc09: '/assets/images/i-eyes.svg',
  acc10: '/assets/images/i-mouth.svg',
  acc11: '/assets/images/i-ear.svg',
  acc12: '/assets/images/i-background.svg',
  animate: '/assets/images/animate01.svg',
  collection: '/assets/images/i-collection.svg',
  repeat: '/assets/images/i-repeat.svg',
  img01: '/assets/images/camille01.png',
  pngwing: '/assets/images/pngwing.png',
  avatar01: '/assets/images/azuki.svg',
  avatar02: '/assets/images/azuki02.svg',
  avatar03: '/assets/images/azuki03.svg',
  avatar04: '/assets/images/azuki04.svg',
  documents: '/assets/images/documents.svg',
  copy: '/assets/images/i-copy.svg',
  alln: '/assets/images/alln.jpg',
  pagebg: '/assets/images/pagbg.png',
  team01: '/assets/images/team01.png',
  team02: '/assets/images/team02.png',
  team03: '/assets/images/team03.png',
  team04: '/assets/images/team04.png',
  team05: '/assets/images/team05.png',
  team06: '/assets/images/team06.png',
  team07: '/assets/images/team07.png',
  team08: '/assets/images/team08.png',
  teamban: '/assets/images/teamban.png',
  discord2: '/assets/images/discord2.svg',
  twitter2: '/assets/images/twitter2.svg',
  boat2: '/assets/images/boat2.svg',
  landingLogo: '/assets/images/landing-logo.svg',
  buttonBackground: '/assets/images/creativebtnBg.png',
  buttonBackground02: '/assets/images/creativebtnBg02.png',
  avtImg: '/assets/images/charfarme01.png',
  locationBg: '/assets/images/location-btnBg.png',
  blackFrame: '/assets/images/frame02.png',
  logolandingv: '/assets/images/logolandingv.png',
  borderFrame: '/assets/images/border-frame.png',
  stone_img: '/assets/images/stone-img.png',
  iconDisordNew: '/assets/images/icon-discord.svg',
  iconTwitterNew: '/assets/images/new-twitter.svg',
  iconBoatNew: '/assets/images/new-boat.svg',
};