import Game from "../components/map";
import Home from "./Home";
import Nfts from "./Nfts";
import Docs from "./Docs";
import Lore from "./Lore";
import { ROUTE_PATH } from '../constants/routes';

const routes = [
  { path: ROUTE_PATH.HOME, component: <Home /> },
  { path: ROUTE_PATH.GAME, component: <Game /> },
  { path: ROUTE_PATH.GALLERY, component: <Nfts /> },
  { path: ROUTE_PATH.LORE, component: <Lore /> },
  { path: ROUTE_PATH.DOCS, component: <Docs /> },
];

export default routes;
